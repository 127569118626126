import { useState, useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { getStringByLanguage } from '../utils/utils';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Screen resolution interface
 * @width screen width.
 * @height screen height.
 */
export interface ScreenResolution {
  width: number;
  height: number;
}

/**
 * Device type.
 * @DESKTOP wider than 962px.
 * @TABLET wider than 576px, smaller than 962px.
 * @MOBILE smaller than 576px.
 */
export enum DEVICE_TYPE {
  DESKTOP = 'kut-device-desktop',
  TABLET = 'kut-device-tablet',
  MOBILE = 'kut-device-mobile',
}

/**
 * Get window(viewport) resolution.
 * @returns An object that contains two keys, width and height.
 */
export const getWindowDimensions = (): ScreenResolution => {
  const { innerWidth: width, innerHeight: height } = window;

  return {
    width,
    height,
  };
};

/**
 * Hook to get deviceType.
 * @returns deviceType
 */
export function useDeviceType() {
  const [deviceType, setDeviceType] = useState(DEVICE_TYPE.DESKTOP);

  useEffect(() => {
    const demensions = getWindowDimensions();
    setDeviceType(
      demensions.width > 962
        ? DEVICE_TYPE.DESKTOP
        : demensions.width > 576
        ? DEVICE_TYPE.TABLET
        : DEVICE_TYPE.MOBILE,
    );
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const demensions = getWindowDimensions();
      setDeviceType(
        demensions.width > 962
          ? DEVICE_TYPE.DESKTOP
          : demensions.width > 576
          ? DEVICE_TYPE.TABLET
          : DEVICE_TYPE.MOBILE,
      );
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [deviceType]);

  return {
    deviceType,
    isDesktop: deviceType === DEVICE_TYPE.DESKTOP,
    isTablet: deviceType === DEVICE_TYPE.TABLET,
    isMobile: deviceType === DEVICE_TYPE.MOBILE,
  };
}

/**
 * Hook to get window demensions.
 * @returns windowDemensions
 */
export function useWindowDemensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

/**
 * Hook to get window demensions.
 * @returns [deviceType, windowDemensions]
 */
export function useHTMLScroll() {
  const [scrollPosition, setScrollPosition] = useState<number>();
  const [isEndOfScroll, setIsEndOfScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      const scrollBottom = scrollTop + windowHeight;

      setScrollPosition(scrollTop);

      if (scrollBottom >= documentHeight) {
        setIsEndOfScroll(true);
      } else {
        setIsEndOfScroll(false);
      }
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return { scrollPosition, isEndOfScroll };
}

export function useUrlTrack() {
  const imagesByUrls: {
    [key: string]: {
      title: string;
      favicon: string;
      logo: string;
      logo_min: string;
      auth_background: string;
      description: string;
    };
  } = {
    default: {
      title: getStringByLanguage(
        'WESMART_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES',
      ),
      favicon: 'uploads/domain/favicon-default.ico',
      logo: 'uploads/domain/logo-default.svg',
      logo_min: 'uploads/domain/logo-default-min.png',
      auth_background: 'uploads/domain/auth-default-background.png',
      description: getStringByLanguage([
        'DEVELOPED_BY_WESMART_ALL_RIGHTS_RESERVED_2023',
      ]),
    },
    'https://go.sween.fr': {
      title: getStringByLanguage(
        'SWEEN_BRINGS_A_DIGITAL_PLATFORM_AND_SERVICES_TO_OPERATE_RENEWABLE_ENERGY_COMMUNITIES',
      ),
      favicon: 'uploads/domain/favicon-sween.ico',
      logo: 'uploads/domain/logo-sween.svg',
      logo_min: 'uploads/domain/logo-sween-min.png',
      auth_background: 'uploads/domain/auth-sween-background.png',
      description: getStringByLanguage(['POWERED_BY', 'WESMART']),
    },
  };

  const [urlInfo, setUrlInfo] = useState<{
    title: string;
    favicon: string;
    logo: string;
    logo_min: string;
    description: string;
    auth_background: string;
  }>(imagesByUrls['default']);

  useEffect(() => {
    if (imagesByUrls[window.location.origin]) {
      setUrlInfo(imagesByUrls[window.location.origin]);
    }
  }, []);
  return urlInfo;
}
