import { FC, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import cn from 'classnames';

import { useUrlTrack } from '../../services/hooks';
import { getServerImageUrl } from '../../utils/utils';

import styles from './styles.module.scss';

export interface MenuItemProps {
  icon: JSX.Element;
  title?: string;
  path?: string;
  unReadCount?: number;
  action?: () => Promise<void>;
}

interface ComProps extends HTMLAttributes<HTMLDivElement> {
  topMenuList: MenuItemProps[];
  bottomMenuList: MenuItemProps[];
  panelStatus: boolean;
  handleSidebarFold: () => void;
  handlePageTitle: (menuItem: MenuItemProps) => void;
}

const Sidebar: FC<ComProps> = ({
  className,
  topMenuList,
  bottomMenuList,
  panelStatus,
  handleSidebarFold,
  handlePageTitle,
}) => {
  const logoContainer = useRef<HTMLDivElement>(null);
  const [logoSize, setLogoSize] = useState({ width: 0, height: 0 });
  const params = useLocation();
  const urlInfo = useUrlTrack();

  const handleResize = () => {
    setLogoSize({
      width: logoContainer.current?.clientWidth || 0,
      height: logoContainer.current?.clientHeight || 0,
    });
  };

  useEffect(() => {
    const container = logoContainer.current;

    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });

    if (container) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, []);

  return (
    <div className={cn([styles[`sidebar-container`], className])}>
      <div className={cn([styles['sidebar-scrollable-section']])}>
        <div className={styles[`top-sidebar-section`]}>
          <div ref={logoContainer} className={styles[`logo-img`]}>
            <img
              alt={`logo-max`}
              src={getServerImageUrl(urlInfo.logo)}
              width={145}
              height={26}
              style={{
                display: logoSize.width >= 150 ? 'block' : 'none',
                width: '100%',
              }}
            />
            <img
              alt={`logo-min`}
              src={getServerImageUrl(urlInfo.logo_min)}
              width={47}
              height={26}
              style={{
                width: 'inherit',
                display: logoSize.width >= 150 ? 'none' : 'block',
              }}
            />
          </div>
          <div className={styles[`menu-list-wrapper`]}>
            {topMenuList.map((item, i) => {
              return (
                <div
                  className={cn([
                    styles[`menu-list-item`],
                    params.pathname.indexOf(item.path || '') === 0
                      ? styles['active']
                      : '',
                  ])}
                  onClick={() => handlePageTitle(item)}
                  key={i}
                >
                  <>{item.icon}</>
                  {panelStatus && (
                    <span className={styles['menu-list-item-text']}>
                      {item.title}
                    </span>
                  )}
                  {item.unReadCount && item.unReadCount > 0 ? (
                    <span className={styles[`unread-count-wrapper`]}>
                      {panelStatus && item.unReadCount}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles[`bottom-sidebar-section`]}>
          <div className={styles[`menu-list-wrapper`]}>
            {bottomMenuList.map((item, i) => {
              return (
                <div
                  className={cn([
                    styles[`menu-list-item`],
                    params.pathname.indexOf(item.path || '') === 0
                      ? 'active'
                      : '',
                  ])}
                  onClick={() => handlePageTitle(item)}
                  key={i}
                >
                  <>{item.icon}</>
                  {panelStatus && <>{item.title}</>}
                  {item.unReadCount && item.unReadCount > 0 ? (
                    <span className={styles[`unread-count-wrapper`]}>
                      {panelStatus && item.unReadCount}
                    </span>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <span
        className={styles[`sidebar-fold-button`]}
        onClick={() => handleSidebarFold()}
      >
        {!panelStatus ? <FaChevronRight /> : <FaChevronLeft />}
      </span>
    </div>
  );
};

export default Sidebar;
