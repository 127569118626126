import { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import { BsChevronDown, BsChevronUp, BsSun } from 'react-icons/bs';
import { GoHome } from 'react-icons/go';
import * as DateFNS from 'date-fns';
import cn from 'classnames';
import { Link, useParams } from 'react-router-dom';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import { MenuItemProps } from '../Sidebar/Sidebar';
import MenuModal from '../MenuModal/MenuModal';
import Notification from '../../components/Notification/Notification';
import { useLoadingBars } from '../../components/LoadingBar/LoadingBar';
import { ReactComponent as SwitchIcon } from '../../assets/images/switch-role.svg';

import {
  getAvaliableLanguage,
  getLocationName,
  getServerImageUrl,
  getStringByLanguage,
  getWeatherInfo,
  languageLocales,
} from '../../utils/utils';
import {
  useAppDispatch,
  useAppSelector,
  useUrlTrack,
} from '../../services/hooks';
import {
  getPersonalView,
  GPSLocation,
  setPersonalView,
  setScrollStatus,
} from '../../services/reducers/sharedReducer';
import { getLoginedUserInfo } from '../../services/reducers/userReducer';
import { defaultAvatars } from '../../utils/constants';
import { _global } from '../../config/config';
import { getCommunityInfo } from '../../apis/communities';
import { getParticipantInfo } from '../../apis/participants';
import { getDeviceInfo } from '../../apis/devices';
import { UserRole, printUserRole } from '../../apis/users';
import { NotificationInfo, getNotifications } from '../../apis/notifications';

import styles from './styles.module.scss';

interface PathProps {
  community?: string;
  participant?: string;
  device?: string;
}

interface FuncGroup {
  itemList: MenuItemProps[];
  handlePageTitle: (menuItem: MenuItemProps) => void;
}

const Header: FC<FuncGroup> = ({ itemList, handlePageTitle }) => {
  // const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const menuButtonRef = useRef<HTMLSpanElement>(null);
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { community_id, participant_id, device_id } = useParams();
  const params = useLocation();
  const urlInfo = useUrlTrack();

  const [modalStatus, setModalStatus] = useState(false);
  const [paths, setPaths] = useState<PathProps>({});
  const [cityName, setCityName] = useState('');
  const [temperature, setTemperature] = useState('0');

  const userInfo = useAppSelector(getLoginedUserInfo);
  const personalView = useAppSelector(getPersonalView);
  const [communityLocation, setCommunityLocation] = useState<GPSLocation>();
  const [notifications, setNotifications] = useState<NotificationInfo[]>([]);

  const handleSwitchView = () => {
    if (!personalView) {
      if (window.location.href.indexOf('participants') !== -1) {
        navigate('/community');
      }
    }
    dispatch(setPersonalView(!personalView));
  };

  const date = DateFNS.format(new Date(), 'EEEE, d LLLL');

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    DateFNS.setDefaultOptions({
      locale: languageLocales[getAvaliableLanguage()],
    });

    setDefaultLocale(getAvaliableLanguage());
  }, [userInfo]);

  useEffect(() => {
    getCityName(communityLocation);
    getTemperature(communityLocation);
  }, [userInfo, communityLocation]);

  useEffect(() => {
    dispatch(setScrollStatus(!modalStatus));
  }, [modalStatus]);

  useEffect(() => {
    communityHandle();
  }, [community_id]);

  useEffect(() => {
    participantHandle();
  }, [participant_id]);

  useEffect(() => {
    deviceHandle();
  }, [device_id]);

  const communityHandle = async () => {
    showLoadingBar();
    const res = await getCommunityInfo(community_id);

    const { data: community } = res.data;

    if (!community) {
      setCommunityLocation({ latitude: 50.8476, longitude: 4.3572 });
    } else if (community.community_latitude && community.community_longitude) {
      setCommunityLocation({
        latitude: community.community_latitude,
        longitude: community.community_longitude,
      });
    }

    setPaths({ ...paths, community: community?.community_name });
    hideLoadingBar();
  };

  const participantHandle = async () => {
    if (participant_id) {
      showLoadingBar();
      const { data: participant } = (
        await getParticipantInfo({
          id: participant_id,
          community_id,
        })
      ).data;

      setPaths({ ...paths, participant: participant?.participant_name });
      hideLoadingBar();
    }
  };

  const deviceHandle = async () => {
    if (participant_id && device_id) {
      showLoadingBar();
      const { data: device } = (
        await getDeviceInfo({
          id: device_id,
          participant_id,
          community_id,
        })
      ).data;

      setPaths({ ...paths, device: device?.device_name });
      hideLoadingBar();
    }
  };

  const getCityName = async (location?: GPSLocation) => {
    if (!location) {
      setCityName(getStringByLanguage('UNKNOWN'));
      return;
    }
    try {
      const { city, state, country } = await getLocationName(location);
      setCityName(city || state || country || '');
    } catch (error) {
      console.log(error);
    }
  };

  const getTemperature = async (location?: GPSLocation) => {
    if (!location) {
      setTemperature(getStringByLanguage('UNKNOWN'));
      return;
    }
    try {
      const data = await getWeatherInfo(location);
      const temp: number = data.main.temp;
      setTemperature(temp.toFixed(1));
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    DateFNS.setDefaultOptions({
      locale: languageLocales[getAvaliableLanguage()],
      weekStartsOn: 1,
    });

    const localeKeys = Object.keys(languageLocales);
    const localeDatas = Object.values(languageLocales);

    localeKeys.forEach((key, i) => registerLocale(key, localeDatas[i]));
    setDefaultLocale(getAvaliableLanguage());
  };

  const handleMenu: MouseEventHandler<HTMLOrSVGElement> = () => {
    setModalStatus(!modalStatus);
  };

  if (!userInfo) {
    return <></>;
  }
  _global.user = userInfo;

  useEffect(() => {
    if (!userInfo.participant || !userInfo.participant.community_id) {
      return;
    }
    getNotifications({
      community_id: userInfo.participant.community_id,
      participant_id: userInfo.participant.id,
    })
      .then((response) => {
        const rNotifs = response.data.data?.[0] || [];
        setNotifications(rNotifs);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div
        className={cn([
          styles['main-body-header-container'],
          styles['kut-device-desktop'],
          'kut-device-desktop',
        ])}
      >
        <div className={cn([styles['main-body-header-info']])}>
          <div className={cn([styles['header-title-wrapper']])}>
            {params.pathname.indexOf('manage') !== -1 ? (
              <div>
                <Link
                  className={cn(['kut-tooltip-item'])}
                  data-tooltip-content={getStringByLanguage('VIEW_COMMUNITIES')}
                  to={`/manage/communities`}
                >
                  <GoHome />
                </Link>
                {community_id && (
                  <>
                    <span>{'>'}</span>
                    <Link
                      className={cn(['kut-tooltip-item'])}
                      data-tooltip-content={
                        participant_id
                          ? getStringByLanguage('VIEW_PARTICIPANTS')
                          : ''
                      }
                      to={`/manage/communities/${community_id}/participants`}
                    >
                      {paths.community}
                    </Link>
                    {participant_id && (
                      <>
                        <span>{'>'}</span>
                        <Link
                          className={cn(['kut-tooltip-item'])}
                          data-tooltip-content={
                            device_id
                              ? window.location.href.indexOf('devices') !== -1
                                ? getStringByLanguage('VIEW_DEVICES')
                                : getStringByLanguage('VIEW_DOCUMENTS')
                              : ''
                          }
                          to={
                            device_id
                              ? `/manage/communities/${community_id}/participants/${participant_id}/devices`
                              : '#'
                          }
                        >
                          {paths.participant}
                        </Link>
                        {device_id && (
                          <>
                            <span>{'>'}</span>
                            <Link to={`#`}>{paths.device}</Link>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                <span>
                  {window.location.href.indexOf('streams') !== -1
                    ? getStringByLanguage('STREAMS')
                    : window.location.href.indexOf('devices') !== -1
                    ? getStringByLanguage('DEVICES')
                    : window.location.href.indexOf('documents') !== -1
                    ? getStringByLanguage('DOCUMENTS')
                    : window.location.href.indexOf('participants') !== -1
                    ? getStringByLanguage('PARTICIPANTS')
                    : getStringByLanguage('COMMUNITIES')}
                </span>
              </div>
            ) : (
              <>
                {paths.community}&nbsp; <BsChevronDown />
              </>
            )}
          </div>
          <div className={cn([styles['user-info-wrapper']])}>
            <div className={cn([styles['user-info-detail-wrapper']])}>
              <p className={cn([styles['user-info-name-label']])}>
                {userInfo.first_name} {userInfo.last_name}
              </p>
              <p className={cn([styles['user-info-group-name-label']])}>
                {userInfo.role === UserRole.Community_Manager ? (
                  <>
                    <span onClick={handleSwitchView}>
                      <SwitchIcon></SwitchIcon>
                      {personalView
                        ? getStringByLanguage('SWITCH_TO_MANAGER_VIEW')
                        : getStringByLanguage('SWITCH_TO_PARTICIPANT_VIEW')}
                    </span>
                  </>
                ) : (
                  printUserRole(userInfo.role)
                )}
              </p>
            </div>
            <div className={cn([styles['user-info-avatar-wrapper']])}>
              <img
                alt="user-avatar"
                className={cn([styles['user-info-avatar']])}
                src={
                  (userInfo.avatar && getServerImageUrl(userInfo.avatar)) ||
                  defaultAvatars[userInfo.gender] ||
                  defaultAvatars['Male']
                }
                width={45}
                height={45}
              />
              <span
                className={cn([
                  styles['is-online-flag-section'],
                  userInfo.role ? '' : styles['deactive'],
                ])}
              ></span>
            </div>
            <div className={cn([styles['dropdown-button-wrapper']])}>
              <BsChevronUp />
              <BsChevronDown />
            </div>
          </div>
        </div>
        <div className={cn([styles['main-body-header-location']])}>
          <p className={cn([styles['main-body-header-location-date']])}>
            {date}
          </p>
          <p className={cn([styles['main-body-header-location-temperature']])}>
            <BsSun />
            {temperature}°
          </p>
          <p className={cn([styles['main-body-header-location-cityname']])}>
            {cityName}
            <BsChevronDown />
          </p>
        </div>
      </div>
      <div
        className={cn([
          styles['main-body-header-container'],
          styles['kut-device-mobile'],
          'kut-device-mobile',
        ])}
      >
        <div className={cn([styles['main-body-header-info']])}>
          <span ref={menuButtonRef} onClick={handleMenu}>
            <FaBars />
          </span>
          <img
            alt="Logo"
            src={getServerImageUrl(urlInfo.logo_min)}
            width={48}
            height={'auto'}
          />
          <div className={cn([styles['user-info-wrapper']])}>
            <div className={cn([styles['user-info-avatar-wrapper']])}>
              <img
                alt="user-avatar"
                className={cn([styles['user-info-avatar']])}
                src={
                  (userInfo.avatar && getServerImageUrl(userInfo.avatar)) ||
                  defaultAvatars[userInfo.gender] ||
                  defaultAvatars['Male']
                }
                width={47}
                height={47}
              />
              <span
                className={cn([
                  styles['is-online-flag-section'],
                  userInfo.role ? '' : styles['deactive'],
                ])}
              ></span>
            </div>
          </div>
        </div>
        <div className={cn([styles['header-title-wrapper']])}>
          {userInfo.role === UserRole.Super_Admin ? (
            <div>
              <Link to={`/manage/communities`}>
                <GoHome />
              </Link>
              {device_id ? (
                <Link
                  to={`/manage/communities/${community_id}/participants/${participant_id}/devices`}
                >
                  <span>{'<'}</span>
                  {paths.device}
                </Link>
              ) : participant_id ? (
                <Link to={`/manage/communities/${community_id}/participants`}>
                  <span>{'<'}</span>
                  {paths.participant}
                </Link>
              ) : community_id ? (
                <Link to={`/manage/communities`}>
                  <span>{'<'}</span>
                  {paths.community}
                </Link>
              ) : (
                <></>
              )}
              <span>
                {device_id
                  ? paths.device
                    ? getStringByLanguage('STREAMS')
                    : ''
                  : participant_id
                  ? paths.participant
                    ? window.location.href.indexOf('devices') !== -1
                      ? getStringByLanguage('DEVICES')
                      : getStringByLanguage('DOCUMENTS')
                    : ''
                  : community_id
                  ? paths.community
                    ? getStringByLanguage('PARTICIPANTS')
                    : ''
                  : getStringByLanguage('COMMUNITIES')}
              </span>
            </div>
          ) : (
            <>
              {paths.community}&nbsp; <BsChevronDown />
            </>
          )}
        </div>
        <div className={cn([styles['main-body-header-location']])}>
          <p className={cn([styles['main-body-header-location-date']])}>
            {date}
          </p>
          <p className={cn([styles['main-body-header-location-temperature']])}>
            <BsSun />
            {temperature}°
          </p>
          <p className={cn([styles['main-body-header-location-cityname']])}>
            {cityName}
            <BsChevronDown />
          </p>
        </div>
        <MenuModal
          modalStatus={modalStatus}
          itemList={itemList}
          handlePageTitle={handlePageTitle}
          setModalStatus={setModalStatus}
          listeners={[menuButtonRef]}
        />
      </div>
      <div
        className={cn([
          styles['main-body-header-container'],
          styles['kut-device-mobile'],
          styles['fake'],
          'kut-device-mobile',
        ])}
      >
        <div className={cn([styles['main-body-header-info']])}>
          <FaBars />
          <img alt="Logo" src={urlInfo.logo_min} width={48} height={'auto'} />
          <div className={cn([styles['user-info-wrapper']])}>
            <div className={cn([styles['user-info-avatar-wrapper']])}>
              <img
                alt="user-avatar"
                className={cn([styles['user-info-avatar']])}
                src={
                  (userInfo.avatar && getServerImageUrl(userInfo.avatar)) ||
                  defaultAvatars[userInfo.gender] ||
                  defaultAvatars['Male']
                }
                width={47}
                height={47}
              />
              <span
                className={cn([
                  styles['is-online-flag-section'],
                  userInfo.role ? '' : styles['deactive'],
                ])}
              ></span>
            </div>
          </div>
        </div>
        <div className={cn([styles['header-title-wrapper']])}>
          {userInfo.role === UserRole.Super_Admin ? (
            <div>
              <Link to={`/manage/communities`}>
                <GoHome />
              </Link>
              {paths.community && (
                <Link to={`/manage/communities/${community_id}/participants`}>
                  {paths.community}
                </Link>
              )}
              {paths.participant && (
                <Link
                  to={`/manage/communities/${community_id}/participants/${participant_id}/devices`}
                >
                  {paths.participant}
                </Link>
              )}
              {paths.device && (
                <Link
                  to={`/admin/communities/${community_id}/participants/${participant_id}/devices/${device_id}/streams`}
                >
                  {paths.device}
                </Link>
              )}
            </div>
          ) : (
            <>
              {paths.community}
              <BsChevronDown />
            </>
          )}
        </div>
        <div className={cn([styles['main-body-header-location']])}>
          <p className={cn([styles['main-body-header-location-date']])}>
            {date}
          </p>
          <p className={cn([styles['main-body-header-location-temperature']])}>
            <BsSun />
            {temperature}°
          </p>
          <p className={cn([styles['main-body-header-location-cityname']])}>
            {cityName}
            <BsChevronDown />
          </p>
        </div>
      </div>
      <Notification data={notifications} />
    </>
  );
};

export default Header;
