import { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SlLogout } from 'react-icons/sl';
import {
  HiOutlineDocumentDuplicate,
  HiOutlineUserGroup,
  HiOutlineGlobeAlt,
} from 'react-icons/hi';
import { HiOutlineGlobeAsiaAustralia } from 'react-icons/hi2';
import { IoSettingsOutline, IoSpeedometerOutline } from 'react-icons/io5';
import { TbHelp } from 'react-icons/tb';
import { BsBarChart } from 'react-icons/bs';
import { BiSend } from 'react-icons/bi';
import { LiaUsersCogSolid } from 'react-icons/lia';
import cn from 'classnames';
import { useCookies } from 'react-cookie';

import { useLoadingBars } from '../components/LoadingBar/LoadingBar';
import Sidebar, { MenuItemProps } from './Sidebar/Sidebar';
import Header from './Header/Header';
import Footer from './Footer/Footer';

import { useAppDispatch, useAppSelector } from '../services/hooks';
import {
  getLoginedUserInfo,
  userLogout,
} from '../services/reducers/userReducer';
import { getPersonalView } from '../services/reducers/sharedReducer';

import { UserRole } from '../apis/users';
import { getNewDocumentsCount } from '../apis/documents';
import { ACCESS_TOKEN, COOKIE_OPTION_DEFAULT } from '../config/config';
import { delayAction, getStringByLanguage } from '../utils/utils';

import styles from './styles.module.scss';

interface ComProps {
  children?: ReactNode;
}

const MainLayout: FC<ComProps> = ({ children = '' }) => {
  const dispatch = useAppDispatch();
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();

  const userInfo = useAppSelector(getLoginedUserInfo);
  const personalView = useAppSelector(getPersonalView);
  const [panelStatus, setPanelStatus] = useState(true);
  const [docLeft, setDocLeft] = useState(0);
  const [, , removeCookie] = useCookies();

  const navigate = useNavigate();
  const [topMenuList, setTopMenuList] = useState<MenuItemProps[]>([]);
  const [bottomMenuList, setBottomMenuList] = useState<MenuItemProps[]>([]);

  useEffect(() => {
    initSideBar();
  }, []);

  useEffect(() => {
    setSidebarMenuList();
  }, [userInfo, personalView, docLeft]);

  const logoutAction = async () => {
    showLoadingBar();
    await delayAction(1000, async () => {
      removeCookie(ACCESS_TOKEN, COOKIE_OPTION_DEFAULT);
      dispatch(userLogout());
    });
    hideLoadingBar();
  };

  const setSidebarMenuList = () => {
    const topMenuList: MenuItemProps[] = [];

    if (
      userInfo?.additional_roles?.Communities ||
      userInfo?.additional_roles?.SubCommunities
    ) {
      topMenuList.push({
        icon: <HiOutlineGlobeAlt />,
        title: getStringByLanguage('COMMUNITIES'),
        path: '/manage/communities',
      });
    }
    if (userInfo?.role === UserRole.Participant || personalView) {
      topMenuList.push(
        {
          icon: <HiOutlineGlobeAsiaAustralia />,
          title: getStringByLanguage('MY_COMMUNITY'),
          path: '/community',
        },
        {
          icon: <HiOutlineDocumentDuplicate />,
          title: getStringByLanguage('MY_DOCUMENTS'),
          path: '/documents',
          unReadCount: docLeft,
        },
        {
          icon: <IoSpeedometerOutline />,
          title: getStringByLanguage('MY_DEVICES'),
          path: '/devices',
        },
        {
          icon: <BsBarChart />,
          title: getStringByLanguage('MY_ENERGY'),
          path: '/energy',
        },
      );
      setBottomMenuList([
        {
          icon: <IoSettingsOutline />,
          title: getStringByLanguage('SETTINGS'),
          path: '/settings',
        },
        {
          icon: <TbHelp />,
          title: getStringByLanguage('GET_HELP'),
          path: '/help',
        },
        {
          icon: <BiSend />,
          title: getStringByLanguage('SEND_FEEDBACK'),
          path: '/feedback',
        },
        {
          icon: <SlLogout />,
          title: getStringByLanguage('LOGOUT'),
          action: logoutAction,
        },
      ]);
    } else if (userInfo?.role === UserRole.Community_Manager) {
      topMenuList.push(
        {
          icon: <HiOutlineGlobeAsiaAustralia />,
          title: getStringByLanguage('MY_COMMUNITY'),
          path: '/community',
        },
        {
          icon: <HiOutlineUserGroup />,
          title: getStringByLanguage('MY_PARTICIPANTS'),
          path: '/participants',
        },
        {
          icon: <HiOutlineDocumentDuplicate />,
          title: getStringByLanguage('MY_DOCUMENTS'),
          path: '/documents',
          unReadCount: docLeft,
        },
        {
          icon: <IoSpeedometerOutline />,
          title: getStringByLanguage('MY_DEVICES'),
          path: '/devices',
        },
        {
          icon: <BsBarChart />,
          title: getStringByLanguage('MY_ENERGY'),
          path: '/energy',
        },
      );
      setBottomMenuList([
        {
          icon: <IoSettingsOutline />,
          title: getStringByLanguage('SETTINGS'),
          path: '/settings',
        },
        {
          icon: <TbHelp />,
          title: getStringByLanguage('GET_HELP'),
          path: '/help',
        },
        {
          icon: <BiSend />,
          title: getStringByLanguage('SEND_FEEDBACK'),
          path: '/feedback',
        },
        {
          icon: <SlLogout />,
          title: getStringByLanguage('LOGOUT'),
          action: logoutAction,
        },
      ]);
    } else {
      topMenuList.push({
        icon: <LiaUsersCogSolid />,
        title: getStringByLanguage('USERS'),
        path: '/manage/users',
      });
      setBottomMenuList([
        {
          icon: <IoSettingsOutline />,
          title: getStringByLanguage('SETTINGS'),
          path: '/settings',
        },
        {
          icon: <SlLogout />,
          title: getStringByLanguage('LOGOUT'),
          action: logoutAction,
        },
      ]);
    }
    setTopMenuList(topMenuList);
  };

  const initSideBar = async () => {
    setSidebarMenuList();
    if (userInfo?.role !== UserRole.Super_Admin) {
      const { data } = await getNewDocumentsCount();
      if (data.data) {
        setDocLeft(data.data);
      }
    }
  };

  const handleSidebarFold = () => {
    setPanelStatus(!panelStatus);
  };

  const handlePageTitle = async (menuItem: MenuItemProps) => {
    if (menuItem.action) {
      await menuItem.action();
    }
    menuItem.path && navigate(menuItem.path);
  };

  return (
    <div
      className={cn([
        styles['dashborad-main-container'],
        !panelStatus ? styles['fold'] : '',
      ])}
    >
      <Sidebar
        className={cn([
          styles['sidebar-container'],
          !panelStatus ? styles['fold'] : '',
        ])}
        topMenuList={topMenuList}
        bottomMenuList={bottomMenuList}
        panelStatus={panelStatus}
        handleSidebarFold={handleSidebarFold}
        handlePageTitle={handlePageTitle}
      />
      <div className={styles['dashboard-main-body-container']}>
        <Header handlePageTitle={handlePageTitle} itemList={bottomMenuList} />
        <div className={styles['main-body-container']}>{children}</div>
        <Footer handlePageTitle={handlePageTitle} itemList={topMenuList} />
      </div>
    </div>
  );
};

export default MainLayout;
