import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BsPlus, BsThreeDotsVertical, BsTrash2 } from 'react-icons/bs';
import { TableColumn } from 'react-data-table-component';
import * as DateFNS from 'date-fns';
import cn from 'classnames';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Popup from 'reactjs-popup';
import { Link } from 'react-router-dom';

import SubSidebar from '../../../../components/SubSidebar/SubSidebar';
import { useLoadingBars } from '../../../../components/LoadingBar/LoadingBar';
import Table from '../../../../components/Table/Table';
import IOSSwitch from '../../../../components/IOSSwitch/IOSSwitch';
import StatusSpan, {
  STATUS,
} from '../../../../components/StatusSpan/StatusSpan';
import Button, { BUTTON_TYPE } from '../../../../components/Button/Button';
import LegalStructureCode from '../../../../components/LegalStructureCode/LegalStructureCode';
import Modal, { useModal } from '../../../../components/Modal/Modal';
import RadioGroup, {
  RadioItemProps,
} from '../../../../components/RadioGroup/RadioGroup';
import InputForm from '../../../../components/InputForm/InputForm';
import SearchInput from '../../../../components/SearchInput/SearchInput';
import ListView, { SortOrder } from '../../../../components/ListView/ListView';
import { sortFieldList, sortStringList } from './Participants.helper';

import { defaultAvatars } from '../../../../utils/constants';
import {
  getServerImageUrl,
  getStringByLanguage,
} from '../../../../utils/utils';
import {
  addUserAndSendInvite,
  addUserPageLog,
  printUserRole,
  UserAndInviteProps,
  UserRole,
} from '../../../../apis/users';
import {
  deleteParticipant,
  getParticipants,
  ParticipantInfo,
  PersonType,
  printPersonType,
  sendInvitation,
  updateParticipant,
} from '../../../../apis/participants';
import {
  useAppSelector,
  useDeviceType,
  useUrlTrack,
} from '../../../../services/hooks';
import { getLoginedUserInfo } from '../../../../services/reducers/userReducer';

import 'reactjs-popup/dist/index.css';
import styles from './styles.module.scss';
import ReactDOMServer from 'react-dom/server';
import { IoArrowBackOutline } from 'react-icons/io5';
import TabView, { useTabView } from '../../../../components/TabView/TabView';

const customStyles = {
  tableWrapper: {
    style: {
      display: 'block',
    },
  },
  head: {
    style: {
      fontSize: '1em',
      fontWeight: 400,
    },
  },
  headRow: {
    style: {
      fontSize: '0.6875em',
    },
  },
  rows: {
    style: {
      fontSize: '0.813em',
      minHeight: '6.5em',
    },
    highlightOnHoverStyle: {
      outlineColor: 'transparent',
    },
  },
};

interface ListItemProps {
  className?: string;
  onChange: (status: boolean) => void;
  ActionComponent: FC<{ data: ParticipantInfo }>;
  checked: boolean;
  data: ParticipantInfo;
  pending?: boolean;
}

const ListItem: FC<ListItemProps> = ({
  className = '',
  onChange,
  ActionComponent,
  checked,
  data,
  pending = false,
}) => {
  const joined = data.joined
    ? DateFNS.format(new Date(data.joined), 'dd/MM/yyyy')
    : getStringByLanguage('NOT_SIGNED_YET');

  return (
    <div className={cn([className, styles['kut-list-item']])}>
      <div className={styles['kut-item-header']}>
        <div className={styles['kut-item-name']}>
          <div
            className={styles['kut-item-avatar']}
            style={{
              backgroundImage:
                (data.user.avatar &&
                  `url(${getServerImageUrl(data.user.avatar)})`) ||
                `url(${defaultAvatars[data.user.gender]})`,
            }}
          />
          <div className={styles['kut-item-text']} style={{ paddingLeft: 10 }}>
            <span>{data.participant_name}</span>
            <span>{joined}</span>
          </div>
        </div>
        <div className={styles['kut-item-actions']}>
          <input
            type="checkbox"
            onChange={(event) => onChange(event.target.checked)}
            checked={checked}
          />
          <ActionComponent data={data} />
        </div>
      </div>
      <div className={styles['kut-item-email']}>
        <div className={styles['kut-item-title']}>
          {getStringByLanguage('EMAIL')}
        </div>
        <div
          className={cn([
            styles['kut-item-content'],
            'kut-text-underline',
            'kut-color-primary',
          ])}
        >
          {data.user.email}
        </div>
      </div>
      <div className={styles['kut-item-postcode']}>
        <div className={styles['kut-item-title']}>
          {getStringByLanguage('POST_CODE')}
        </div>
        <div className={cn([styles['kut-item-content'], 'kut-weight-700'])}>
          {data.participant_postalcode}
        </div>
      </div>
      <div className={styles['kut-item-type']}>
        <div className={styles['kut-item-title']}>
          {getStringByLanguage('TYPE')}
        </div>
        <div className={styles['kut-item-content']}>
          {printPersonType(data.type)}
          {pending && (
            <span className={cn(['kut-color-warning'])}>
              {getStringByLanguage('RESPOND')} »
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

const fieldDict = {
  [getStringByLanguage('NAME')]: 'participants.participant_name',
  [getStringByLanguage('EMAIL')]: 'users.email',
  [getStringByLanguage('POST_CODE')]: 'participants.participant_postalcode',
  [getStringByLanguage('TYPE')]: 'participants.type',
  [getStringByLanguage('JOINED')]: 'participants.joined',
};

interface URLParams {
  search: string;
  type: 'managers' | 'requests' | 'joined';
}

const Participants: FC = () => {
  const { community_id } = useParams();
  const navigate = useNavigate();

  if (!community_id || isNaN(parseInt(community_id))) {
    navigate('/manage/communities');
    return <></>;
  }

  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const { isMobile } = useDeviceType();
  const isDefaultUrl = useUrlTrack();
  const userInfo = useAppSelector(getLoginedUserInfo);

  const [selectAll, setSelectAll] = useState(false);
  const [isEventFromAll, setIsEventFromAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState<{
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<ParticipantInfo>[];
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] });
  const [modalItem, setModalItem] = useState<ParticipantInfo>();
  const [modalText, setModalText] = useState('');
  const [tableParams, setTableParams] = useState<{
    managers: URLParams;
    requests: URLParams;
    joined: URLParams;
  }>({
    managers: {
      search: '',
      type: 'managers',
    },
    requests: {
      search: '',
      type: 'requests',
    },
    joined: {
      search: '',
      type: 'joined',
    },
  });
  const [listUrlParam, setListUrlParam] = useState<{
    managers: URLParams & {
      sort: SortOrder;
      sortField: keyof ParticipantInfo;
    };
    requests: URLParams & {
      sort: SortOrder;
      sortField: keyof ParticipantInfo;
    };
    joined: URLParams & {
      sort: SortOrder;
      sortField: keyof ParticipantInfo;
    };
  }>({
    managers: {
      search: '',
      type: 'managers',
      sort: SortOrder.ASC,
      sortField: 'participant_name',
    },
    requests: {
      search: '',
      type: 'requests',
      sort: SortOrder.ASC,
      sortField: 'participant_name',
    },
    joined: {
      search: '',
      type: 'joined',
      sort: SortOrder.ASC,
      sortField: 'participant_name',
    },
  });
  const [onlyView, setOnlyView] = useState(false);

  const ActionComponent: FC<{ data: ParticipantInfo }> = ({ data }) => (
    <Popup
      className="action-popup"
      trigger={
        <div>
          <BsThreeDotsVertical />
        </div>
      }
      position={['bottom right']}
      arrow={false}
      contentStyle={{
        width: 'initial',
        backgroundColor: 'rgb(var(--content-background-color))',
        borderColor: 'rgb(var(--background-color))',
        padding: '5px 0px',
        boxShadow: '2px 2px 5px 0px rgba(var(--text-color), .2)',
      }}
    >
      <div className={styles['action-item-list']}>
        <span
          className={cn([styles['action-button-invite']])}
          onClick={() => onInvite(data)}
        >
          {getStringByLanguage('INVITE')}
        </span>
        <span
          className={cn([styles['action-button-update']])}
          onClick={() => updateRow(data)}
        >
          {getStringByLanguage('UPDATE')}
        </span>
        <span
          className={cn([styles['action-button-delete']])}
          onClick={() => deleteRow(data)}
        >
          {getStringByLanguage('DELETE')}
        </span>
      </div>
    </Popup>
  );

  const [modalDeleteItems, setModalDeleteItems] = useState<
    Partial<ParticipantInfo>[]
  >([]);

  useEffect(() => {
    addUserPageLog('Participants');
  }, []);

  const columns: TableColumn<ParticipantInfo>[] = [
    {
      name: getStringByLanguage('NAME'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      cell: (data) => {
        return (
          <div
            className={styles['kut-table-name']}
            onClick={() => onRowClicked(data)}
          >
            <div
              className={styles['kut-table-avatar']}
              style={{
                backgroundImage:
                  (data.user.avatar &&
                    `url(${getServerImageUrl(data.user.avatar)})`) ||
                  `url(${defaultAvatars[data.user.gender]})`,
              }}
            />
            <span
              className={(styles['kut-table-text'], cn(['kut-tooltip-item']))}
              style={{ paddingLeft: 10 }}
              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                <div className={styles['table-tooltip-item']}>
                  {data.participant_name}
                </div>,
              )}
            >
              {data.participant_name}
            </span>
          </div>
        );
      },
    },
    {
      name: getStringByLanguage('EMAIL'),
      sortable: true,
      minWidth: '100px',
      compact: true,
      style: {
        paddingLeft: 3,
        paddingRight: 3,
        textDecoration: 'underline',
        cursor: 'pointer',
      },
      cell: (data) => {
        return (
          <span
            className={cn(['kut-tooltip-item'])}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className={styles['table-tooltip-item']}>
                {data.user.email}
              </div>,
            )}
          >
            {data.user.email}
          </span>
        );
      },
    },
    {
      name: getStringByLanguage('POST_CODE'),
      sortable: true,
      minWidth: '50px',
      compact: true,
      style: {
        paddingLeft: 3,
        paddingRight: 3,
        fontWeight: 600,
      },
      cell: (data) => {
        return (
          <span
            className={cn(['kut-tooltip-item'])}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className={styles['table-tooltip-item']}>
                {data.participant_postalcode}
              </div>,
            )}
          >
            {data.participant_postalcode}
          </span>
        );
      },
    },
    {
      name: isDefaultUrl
        ? getStringByLanguage('EAN')
        : getStringByLanguage('PDL'),
      sortable: true,
      minWidth: '75px',
      compact: true,
      cell: (res) => {
        const getNonDuplicatedEanCodes = () => {
          const devices = res.devices;
          const encounteredEanCodes = new Set();
          const nonDuplicatedEanCodes = [];

          for (const device of devices) {
            if (encounteredEanCodes.has(device.ean_code)) {
              continue;
            }
            encounteredEanCodes.add(device.ean_code);
            nonDuplicatedEanCodes.push(device.ean_code);
          }

          return nonDuplicatedEanCodes;
        };

        const nonDuplicatedEanCodes = getNonDuplicatedEanCodes();

        return (
          <div>
            {nonDuplicatedEanCodes.map((data, idx) => (
              <div
                key={idx}
                className={cn(['kut-tooltip-item'])}
                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                  <div className={styles['table-tooltip-item']}>{data}</div>,
                )}
              >
                {data}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      name: getStringByLanguage('TYPE'),
      sortable: true,
      minWidth: '70px',
      compact: true,
      selector: (data) => printPersonType(data.type) || '',
    },
  ];

  const columnDefault: TableColumn<ParticipantInfo>[] = columns.concat([
    {
      name: getStringByLanguage('JOINED'),
      sortable: true,
      minWidth: '6.25rem',
      compact: true,
      cell: (data) => {
        return (
          <span
            className={cn(['kut-tooltip-item'])}
            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
              <div className={styles['table-tooltip-item']}>
                {data.joined &&
                  DateFNS.format(new Date(data.joined), 'dd/MM/yyyy')}
              </div>,
            )}
          >
            {data.joined && DateFNS.format(new Date(data.joined), 'dd/MM/yyyy')}
          </span>
        );
      },
    },
    {
      name: '',
      minWidth: '70px',
      compact: true,
      cell: (data) => {
        return (
          <Link
            className={styles['table-item-view-devices']}
            to={`/manage/communities/${community_id}/participants/${data.id}/devices`}
          >
            {getStringByLanguage('VIEW_DEVICES')} {'>'}
          </Link>
        );
      },
    },
    {
      name: '',
      width: '2.813rem',
      compact: true,
      style: {
        cursor: 'pointer',
      },
      cell: (data) => {
        return <ActionComponent data={data} />;
      },
    },
  ]);

  const columnRequest: TableColumn<ParticipantInfo>[] = columns.concat([
    {
      name: getStringByLanguage('REQUEST_STATUS'),
      sortable: false,
      minWidth: '6.25rem',
      compact: true,
      cell: () => {
        return (
          <span className={styles['kut-table-request-status']}>
            {getStringByLanguage('RESPOND')} »
          </span>
        );
      },
    },
    {
      name: '',
      width: '2.813rem',
      compact: true,
      style: {
        cursor: 'pointer',
      },
      cell: (data) => {
        return <ActionComponent data={data} />;
      },
    },
  ]);

  const personTypes: RadioItemProps[] = [
    {
      value: PersonType.Producer,
      text: printPersonType(PersonType.Producer),
      component: StatusSpan,
      props: {
        IconComponent: () => <></>,
        status: STATUS.WARNING,
      },
    },
    {
      value: PersonType.Consumer,
      text: printPersonType(PersonType.Consumer),
      component: StatusSpan,
      props: {
        IconComponent: () => <></>,
        status: STATUS.SUCCESS,
      },
    },
    {
      value: PersonType.Prosumer,
      text: printPersonType(PersonType.Prosumer),
      component: StatusSpan,
      props: {
        IconComponent: () => <></>,
        status: STATUS.PRIMARY,
      },
    },
  ];

  const userRoles: RadioItemProps[] = [
    {
      value: UserRole.Participant,
      text: printUserRole(UserRole.Participant),
      component: StatusSpan,
      props: {
        IconComponent: () => <></>,
        status: STATUS.PRIMARY,
      },
    },
    {
      value: UserRole.Community_Manager,
      text: printUserRole(UserRole.Community_Manager),
      component: StatusSpan,
      props: {
        IconComponent: () => <></>,
        status: STATUS.PRIMARY,
      },
    },
  ];

  const onRowClicked = (participant: ParticipantInfo) => {
    setModalItem(participant);
    setOnlyView(true);
    updateModal.show();
  };

  const updateRow = (participant: ParticipantInfo) => {
    setModalItem(participant);
    setOnlyView(false);
    updateModal.show();
    tabView.initStatus();
  };

  const deleteRow = (participant: Partial<ParticipantInfo>) => {
    setModalText(
      `${getStringByLanguage('ARE_YOU_SURE_WANT_TO_DELETE')} <b>${
        participant.participant_name
      }</b> ${getStringByLanguage('PARTICIPANT').toLowerCase()}?`,
    );
    setModalDeleteItems([participant]);
    deleteModal.show();
  };

  const onDeleteSelected = () => {
    if (!selectedItems.selectedCount) {
      return;
    }
    setModalText(
      `${getStringByLanguage(
        'ARE_YOU_SURE_WANT_TO_DELETE',
      )} <b>${`${getStringByLanguage('SELECTED').toLowerCase()} ${
        selectedItems.selectedCount
      } ${getStringByLanguage('PARTICIPANTS').toLowerCase()}`}</b>?`,
    );
    setModalDeleteItems(selectedItems.selectedRows);
    deleteModal.show();
  };

  const onCreateNew = () => {
    createModal.show();
    tabView.initStatus();
  };

  const onCreate = async (user: UserAndInviteProps) => {
    showLoadingBar();
    try {
      await addUserAndSendInvite({ data: user });
      toast.success(getStringByLanguage('SUCCESSFULLY_CREATED'));
      createModal.hide();
      setTableParams({
        managers: {
          ...tableParams.managers,
        },
        requests: {
          ...tableParams.requests,
        },
        joined: {
          ...tableParams.joined,
        },
      });
      setListUrlParam({
        managers: {
          ...listUrlParam.managers,
        },
        requests: {
          ...listUrlParam.requests,
        },
        joined: {
          ...listUrlParam.joined,
        },
      });
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onUpdate = async (participant: Partial<ParticipantInfo>) => {
    showLoadingBar();
    try {
      console.log(community_id);
      await updateParticipant(community_id, participant);
      toast.success(getStringByLanguage('SUCCESSFULLY_UPDATED'));
      setTableParams({
        managers: {
          ...tableParams.managers,
        },
        requests: {
          ...tableParams.requests,
        },
        joined: {
          ...tableParams.joined,
        },
      });
      setListUrlParam({
        managers: {
          ...listUrlParam.managers,
        },
        requests: {
          ...listUrlParam.requests,
        },
        joined: {
          ...listUrlParam.joined,
        },
      });
      updateModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onDelete = async () => {
    showLoadingBar();

    const promises = modalDeleteItems.map(async (participant) => {
      if (!participant.id) {
        throw getStringByLanguage('REQUEST_ERROR');
      }
      return await deleteParticipant({
        community_id: community_id,
        id: participant.id,
      });
    });
    try {
      await promises;
      toast.success(getStringByLanguage('SUCCESSFULLY_DELETED'));
      setSelectedItems({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
      });
      setTableParams({
        managers: {
          ...tableParams.managers,
        },
        requests: {
          ...tableParams.requests,
        },
        joined: {
          ...tableParams.joined,
        },
      });
      setListUrlParam({
        managers: {
          ...listUrlParam.managers,
        },
        requests: {
          ...listUrlParam.requests,
        },
        joined: {
          ...listUrlParam.joined,
        },
      });
      deleteModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onInvite = async ({ id }: ParticipantInfo) => {
    showLoadingBar();
    try {
      await sendInvitation(id);
      toast.success(getStringByLanguage('EMAIL_SENT'));
      createModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onItemSelected = (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<ParticipantInfo>[];
  }) => {
    setSelectedItems(selected);
  };

  const onSortChange = (sortField: keyof ParticipantInfo) => {
    const oldSort: SortOrder = listUrlParam.managers.sort;
    if (listUrlParam.managers.sortField === sortField) {
      const sort = oldSort === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
      setListUrlParam({
        managers: {
          ...listUrlParam.managers,
          sort,
        },
        requests: {
          ...listUrlParam.requests,
          sort,
        },
        joined: {
          ...listUrlParam.joined,
          sort,
        },
      });
    } else {
      const sort = oldSort;
      setListUrlParam({
        managers: {
          ...listUrlParam.managers,
          sortField,
          sort,
        },
        requests: {
          ...listUrlParam.requests,
          sortField,
          sort,
        },
        joined: {
          ...listUrlParam.joined,
          sortField,
          sort,
        },
      });
    }
  };

  const createModal = useModal<UserAndInviteProps>({
    onSubmit: onCreate,
    initialValues: {
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      role: UserRole.Participant,
      company_name: '',
      type: PersonType.Consumer,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      first_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      last_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      phone: Yup.string().notRequired(),
      role: Yup.string()
        .oneOf([UserRole.Participant, UserRole.Community_Manager])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      company_name: Yup.string().notRequired(),
      type: Yup.string()
        .oneOf([PersonType.Producer, PersonType.Consumer, PersonType.Prosumer])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      participant_address: Yup.string().nullable().notRequired(),
      participant_city: Yup.string().nullable().notRequired(),
      participant_country: Yup.string().nullable().notRequired(),
      participant_postalcode: Yup.string().nullable().notRequired(),
      participant_idn: Yup.number().nullable().notRequired(),
      participant_office_idn: Yup.number().nullable().notRequired(),
      participant_vat: Yup.number().nullable().notRequired(),
      participant_nace: Yup.number().nullable().notRequired(),
      participant_lscn: Yup.number().nullable().notRequired(),
      participant_lscs: Yup.string().when(
        ['participant_lscn'],
        (participant_lscn, schema) => {
          if (participant_lscn) {
            return schema.required();
          }
          return schema.nullable().notRequired();
        },
      ),
    }),
  });

  const updateModal = useModal<Partial<ParticipantInfo>>({
    onSubmit: onUpdate,
    initialValues: modalItem || {},
    validationSchema: Yup.object().shape({
      participant_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      joined: Yup.date().required(),
      type: Yup.string()
        .oneOf([PersonType.Producer, PersonType.Consumer, PersonType.Prosumer])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      participant_address: Yup.string().nullable().notRequired(),
      participant_city: Yup.string().nullable().notRequired(),
      participant_country: Yup.string().nullable().notRequired(),
      participant_postalcode: Yup.string().nullable().notRequired(),
      participant_idn: Yup.number().nullable().notRequired(),
      participant_office_idn: Yup.number().nullable().notRequired(),
      participant_vat: Yup.number().nullable().notRequired(),
      participant_nace: Yup.number().nullable().notRequired(),
      participant_lscn: Yup.number().nullable().notRequired(),
      participant_lscs: Yup.string().when(
        ['participant_lscn'],
        (participant_lscn, schema) => {
          if (participant_lscn) {
            return schema.required();
          }
          return schema.nullable().notRequired();
        },
      ),
    }),
  });

  const deleteModal = useModal<{ forever?: boolean }>({
    onSubmit: onDelete,
    initialValues: {},
    validationSchema: Yup.object().shape({}),
  });

  const tabView = useTabView({ showTabs: false });

  return (
    <>
      <SubSidebar communityId={community_id} />
      <div className={styles['kut-component-body']}>
        <div className={styles['kut-component-content']}>
          {!isMobile && (
            <div className={cn([styles['kut-component-table-view']])}>
              <div className={styles['kut-component-actions']}>
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-large'],
                  ])}
                  outline={true}
                  onClick={onCreateNew}
                >
                  + {getStringByLanguage('CREATE_NEW')}
                </Button>
                <SearchInput
                  className={styles['kut-component-search']}
                  value={tableParams.managers.search}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setTableParams({
                      managers: {
                        search: event.target.value,
                        type: tableParams.managers.type,
                      },
                      requests: {
                        search: event.target.value,
                        type: tableParams.requests.type,
                      },
                      joined: {
                        search: event.target.value,
                        type: tableParams.joined.type,
                      },
                    })
                  }
                />
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-large'],
                    styles['kut-component-button-delete'],
                    selectedItems.selectedCount ? styles['kut-active'] : '',
                  ])}
                  type={BUTTON_TYPE.DANGER}
                  onClick={onDeleteSelected}
                >
                  {getStringByLanguage('DELETE_SELECTED')}
                </Button>
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-small'],
                  ])}
                  onClick={onCreateNew}
                >
                  <BsPlus />
                </Button>
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-small'],
                    styles['kut-component-button-delete'],
                    selectedItems.selectedCount ? styles['kut-active'] : '',
                  ])}
                  type={BUTTON_TYPE.DANGER}
                  onClick={onDeleteSelected}
                >
                  <BsTrash2 />
                </Button>
              </div>
              <div className={styles['kut-table']}>
                <p className={styles['kut-table-title']}>
                  {getStringByLanguage('COMMUNITY_MANAGERS')}
                </p>
                <div className={styles['kut-table-main']}>
                  <Table
                    pagination={true}
                    requestFunc={(options) =>
                      getParticipants({
                        community_id: Number(community_id),
                        ...options, // Spread any additional options if needed
                      })
                    }
                    urlParam={tableParams.managers}
                    fieldDict={fieldDict}
                    columns={columnDefault}
                    customStyles={customStyles}
                    selectableRows
                    onSelectedRowsChange={onItemSelected}
                  />
                </div>
              </div>
              <div className={styles['kut-table']}>
                <div className={styles['kut-table-request']}>
                  <p className={styles['kut-table-title']}>
                    {getStringByLanguage('REQUESTS_TO_JOIN')}
                  </p>
                  <div className={styles['kut-table-title']}>
                    {getStringByLanguage('ALWAYS_ACCEPT_REQUESTS')}
                    <IOSSwitch
                      className={styles['kut-table-switch']}
                      type="warning"
                      sx={{ m: 1, width: 17, height: 10, margin: 0 }}
                      defaultChecked
                    />
                  </div>
                </div>
                <div className={styles['kut-table-main']}>
                  <div className="kut-device-desktop">
                    <Table
                      pagination={true}
                      requestFunc={(options) =>
                        getParticipants({
                          community_id: Number(community_id),
                          ...options, // Spread any additional options if needed
                        })
                      }
                      urlParam={tableParams.requests}
                      fieldDict={fieldDict}
                      columns={columnRequest}
                      customStyles={customStyles}
                      selectableRows
                      onSelectedRowsChange={onItemSelected}
                    />
                  </div>
                </div>
              </div>
              <div className={styles['kut-table']}>
                <p className={styles['kut-table-title']}>
                  {getStringByLanguage('COMMUNITY_MEMBERS')}
                </p>
                <div className={styles['kut-table-main']}>
                  <div className="kut-device-desktop">
                    <Table
                      pagination={true}
                      requestFunc={(options) =>
                        getParticipants({
                          community_id: Number(community_id),
                          ...options, // Spread any additional options if needed
                        })
                      }
                      urlParam={tableParams.joined}
                      fieldDict={fieldDict}
                      columns={columnDefault}
                      customStyles={customStyles}
                      selectableRows
                      onSelectedRowsChange={onItemSelected}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {isMobile && (
            <div className={cn([styles['kut-component-list-view']])}>
              <div className={styles['kut-component-header']}>
                <div className={cn([styles['kut-component-sort']])}>
                  <span>{getStringByLanguage('SORT_BY')}</span>
                  {sortFieldList.map((field, i) => (
                    <span
                      key={i}
                      className={cn([
                        styles['kut-component-sort-option'],
                        listUrlParam.managers.sortField === field
                          ? styles['active']
                          : '',
                        styles[listUrlParam.managers.sort],
                      ])}
                      onClick={() => onSortChange(field)}
                    >
                      {sortStringList[i]}
                    </span>
                  ))}
                </div>
                <div className={cn([styles['kut-component-select-all']])}>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={(event) => {
                      const status = event.target.checked;
                      setSelectAll(status);
                      setIsEventFromAll(true);
                    }}
                    ref={(input) => {
                      if (input) {
                        const status = selectAll;
                        if (!status && selectedItems.selectedCount) {
                          input.indeterminate = true;
                          return;
                        }
                        input.indeterminate = false;
                        status ? setSelectAll(true) : setSelectAll(false);
                      }
                    }}
                  />
                  <span>{getStringByLanguage('SELECT_ALL')}</span>
                </div>
              </div>
              <div className={styles['kut-component-actions']}>
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-large'],
                  ])}
                  outline={true}
                  onClick={onCreateNew}
                >
                  + {getStringByLanguage('CREATE_NEW')}
                </Button>
                <SearchInput
                  className={styles['kut-component-search']}
                  value={listUrlParam.managers.search}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setListUrlParam({
                      managers: {
                        ...listUrlParam.managers,
                        search: event.target.value,
                      },
                      requests: {
                        ...listUrlParam.requests,
                        search: event.target.value,
                      },
                      joined: {
                        ...listUrlParam.joined,
                        search: event.target.value,
                      },
                    })
                  }
                />
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-large'],
                    styles['kut-component-button-delete'],
                    selectedItems.selectedCount ? styles['kut-active'] : '',
                  ])}
                  type={BUTTON_TYPE.DANGER}
                  onClick={onDeleteSelected}
                >
                  {getStringByLanguage('DELETE_SELECTED')}
                </Button>
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-small'],
                  ])}
                  onClick={onCreateNew}
                >
                  <BsPlus />
                </Button>
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-small'],
                    styles['kut-component-button-delete'],
                    selectedItems.selectedCount ? styles['kut-active'] : '',
                  ])}
                  type={BUTTON_TYPE.DANGER}
                  onClick={onDeleteSelected}
                >
                  <BsTrash2 />
                </Button>
              </div>
              <div className={styles['kut-table']}>
                <p className={styles['kut-table-title']}>
                  {getStringByLanguage('COMMUNITY_MANAGERS')}
                </p>
                <div className={styles['kut-table-main']}>
                  <ListView
                    className={styles['kut-list-container']}
                    urlParam={listUrlParam.managers}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    isEventFromAll={isEventFromAll}
                    setIsEventFromAll={setIsEventFromAll}
                    onItemSelected={onItemSelected}
                    requestFunc={async (options) =>
                      await getParticipants({
                        ...options,
                        community_id: Number(community_id),
                      })
                    }
                    ChildComponent={({
                      className,
                      onChange,
                      checked,
                      data,
                    }: {
                      className: string;
                      onChange: (status: boolean) => void;
                      checked: boolean;
                      data: ParticipantInfo;
                    }) => (
                      <ListItem
                        className={cn([className])}
                        onChange={onChange}
                        ActionComponent={ActionComponent}
                        checked={checked}
                        data={data}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles['kut-table']}>
                <div className={styles['kut-table-request']}>
                  <p className={styles['kut-table-title']}>
                    {getStringByLanguage('REQUESTS_TO_JOIN')}
                  </p>
                  <div className={styles['kut-table-title']}>
                    {getStringByLanguage('ALWAYS_ACCEPT_REQUESTS')}
                    <IOSSwitch
                      className={styles['kut-table-switch']}
                      type="warning"
                      sx={{ m: 1, width: 17, height: 10, margin: 0 }}
                      defaultChecked
                    />
                  </div>
                </div>
                <div className={styles['kut-table-main']}>
                  <ListView
                    className={styles['kut-list-container']}
                    urlParam={listUrlParam.requests}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    isEventFromAll={isEventFromAll}
                    setIsEventFromAll={setIsEventFromAll}
                    onItemSelected={onItemSelected}
                    requestFunc={async (options) =>
                      await getParticipants({
                        ...options,
                        community_id: Number(community_id),
                      })
                    }
                    ChildComponent={({
                      className,
                      onChange,
                      checked,
                      data,
                    }: {
                      className: string;
                      onChange: (status: boolean) => void;
                      checked: boolean;
                      data: ParticipantInfo;
                    }) => (
                      <ListItem
                        className={cn([className])}
                        onChange={onChange}
                        ActionComponent={ActionComponent}
                        checked={checked}
                        data={data}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={styles['kut-table']}>
                <p className={styles['kut-table-title']}>
                  {getStringByLanguage('COMMUNITY_MEMBERS')}
                </p>
                <div className={styles['kut-table-main']}>
                  <ListView
                    className={styles['kut-list-container']}
                    urlParam={listUrlParam.joined}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    isEventFromAll={isEventFromAll}
                    setIsEventFromAll={setIsEventFromAll}
                    onItemSelected={onItemSelected}
                    requestFunc={async (options) =>
                      await getParticipants({
                        ...options,
                        community_id: Number(community_id),
                      })
                    }
                    ChildComponent={({
                      className,
                      onChange,
                      checked,
                      data,
                    }: {
                      className: string;
                      onChange: (status: boolean) => void;
                      checked: boolean;
                      data: ParticipantInfo;
                    }) => (
                      <ListItem
                        className={cn([className])}
                        onChange={onChange}
                        checked={checked}
                        ActionComponent={ActionComponent}
                        data={data}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <Modal {...createModal}>
          {(formik) => {
            return (
              <form className={cn([styles['kut-modal-form']])}>
                <p className={cn([styles['kut-title']])}>
                  {getStringByLanguage(['CREATE', 'PARTICIPANT'])}
                </p>
                <hr />
                <div className={cn([styles['kut-body']])}>
                  <TabView
                    {...tabView}
                    datasets={[
                      {
                        element: (
                          <>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-email'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('EMAIL')}
                              name={'email'}
                              onChange={formik.handleChange}
                              required
                              value={formik.values.email}
                              error={
                                formik.touched.email && formik.errors.email
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-first-name'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('FIRST_NAME')}
                              name={'first_name'}
                              onChange={formik.handleChange}
                              required
                              value={formik.values.first_name || ''}
                              error={
                                formik.touched.first_name &&
                                formik.errors.first_name
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-last-name'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('LAST_NAME')}
                              name={'last_name'}
                              onChange={formik.handleChange}
                              required
                              value={formik.values.last_name || ''}
                              error={
                                formik.touched.last_name &&
                                formik.errors.last_name
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-phone'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('PHONE')}
                              name={'phone'}
                              onChange={formik.handleChange}
                              value={formik.values.phone || ''}
                              error={
                                formik.touched.phone && formik.errors.phone
                              }
                            />
                            <div
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-role'],
                                styles['kut-input-form'],
                                styles['kut-radio-group'],
                              ])}
                            >
                              <div>
                                <p>{getStringByLanguage('ROLE')}</p>
                                <RadioGroup
                                  value={formik.values.role}
                                  groupId={'role'}
                                  onChange={(value) => {
                                    formik.setFieldValue('role', value);
                                  }}
                                  itemList={userRoles}
                                />
                              </div>
                            </div>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-company-name'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('COMPANY_NAME')}
                              name={'company_name'}
                              onChange={formik.handleChange}
                              value={formik.values.company_name || ''}
                              error={
                                formik.touched.company_name &&
                                formik.errors.company_name
                              }
                            />
                            <div
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-type'],
                                styles['kut-input-form'],
                                styles['kut-radio-group'],
                              ])}
                            >
                              <div>
                                <p>{getStringByLanguage('PERSON_TYPE')}</p>
                                <RadioGroup
                                  value={formik.values.type}
                                  groupId={'type'}
                                  onChange={(value) => {
                                    formik.setFieldValue('type', value);
                                  }}
                                  itemList={personTypes}
                                />
                              </div>
                            </div>
                          </>
                        ),
                      },
                      {
                        element: (
                          <>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-address'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['ADDRESS'])}
                              name={'participant_address'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_address || ''}
                              error={
                                formik.touched.participant_address &&
                                formik.errors.participant_address
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-city'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['CITY'])}
                              name={'participant_city'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_city || ''}
                              error={
                                formik.touched.participant_city &&
                                formik.errors.participant_city
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-country'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['COUNTRY'])}
                              name={'participant_country'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_country || ''}
                              error={formik.errors.participant_country}
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-postcode'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['POSTCODE'])}
                              name={'participant_postalcode'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_postalcode || ''}
                              error={
                                formik.touched.participant_postalcode &&
                                formik.errors.participant_postalcode
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-idn'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['ID_NUMBER'])}
                              name={'participant_idn'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_idn || ''}
                              error={
                                formik.touched.participant_idn &&
                                formik.errors.participant_idn
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-office-idn'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage([
                                'OFFICE_ID_NUMBER',
                              ])}
                              name={'participant_office_idn'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_office_idn || ''}
                              error={
                                formik.touched.participant_office_idn &&
                                formik.errors.participant_office_idn
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-dso'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['VAT'])}
                              name={'participant_vat'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_vat || ''}
                              error={
                                formik.touched.participant_vat &&
                                formik.errors.participant_vat
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-nace'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['NACE'])}
                              name={'participant_nace'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_nace || ''}
                              error={
                                formik.touched.participant_nace &&
                                formik.errors.participant_nace
                              }
                            />
                            <LegalStructureCode
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-legal-structure-code'],
                                styles['kut-input-form'],
                              ])}
                              name="participant_lscn"
                              heading={getStringByLanguage([
                                'LEGAL_STRUCTURE_CODE',
                              ])}
                              onChange={({ lscn, lscs }) => {
                                formik.setFieldValue('participant_lscn', lscn);
                                formik.setFieldValue('participant_lscs', lscs);
                              }}
                              readOnly={onlyView}
                              value={{
                                lscn: formik.values.participant_lscn,
                                lscs: formik.values.participant_lscs,
                              }}
                              error={
                                (formik.touched.participant_lscn &&
                                  formik.errors.participant_lscn) ||
                                (formik.touched.participant_lscn &&
                                  formik.errors.participant_lscs)
                              }
                            />
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  {tabView.status.activeTab === 0 ? (
                    <>
                      <Button
                        outline
                        type={BUTTON_TYPE.INFO}
                        style={{
                          padding: 10,
                          fontSize: '1em',
                          visibility: 'hidden',
                          transition: 'none',
                        }}
                      >
                        <IoArrowBackOutline />
                      </Button>
                      <div className={cn([styles['kut-form-submit']])}>
                        <Button
                          onClick={async () => {
                            const result = await formik.validateForm();
                            const fields = [
                              'email',
                              'first_name',
                              'last_name',
                              'phone',
                              'role',
                              'company_name',
                              'type',
                            ];
                            fields.forEach((field) =>
                              formik.setFieldTouched(field, true),
                            );
                            if (
                              fields.reduce<boolean>(
                                (check, field) => check || !!result[field],
                                false,
                              )
                            ) {
                              return;
                            }
                            tabView.status.switchTab(1);
                          }}
                        >
                          {getStringByLanguage('CONTINUE')}
                        </Button>
                        <Button
                          type={BUTTON_TYPE.DANGER}
                          onClick={() => createModal.hide()}
                        >
                          {getStringByLanguage('CANCEL')}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        outline
                        type={BUTTON_TYPE.INFO}
                        onClick={() => tabView.status.switchTab(0)}
                        style={{
                          padding: 10,
                          fontSize: '1em',
                        }}
                      >
                        <IoArrowBackOutline />
                      </Button>
                      <div className={cn([styles['kut-form-submit']])}>
                        <Button onClick={() => formik.handleSubmit()}>
                          {getStringByLanguage('CREATE')}
                        </Button>
                        <Button
                          type={BUTTON_TYPE.DANGER}
                          onClick={() => createModal.hide()}
                        >
                          {getStringByLanguage('CANCEL')}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...updateModal}>
          {(formik) => {
            return (
              <form className={cn([styles['kut-modal-form']])}>
                <p className={cn([styles['kut-title']])}>
                  {onlyView
                    ? getStringByLanguage('PARTICIPANT')
                    : getStringByLanguage(['UPDATE', 'PARTICIPANT'])}
                </p>
                <hr />
                <div className={cn([styles['kut-body']])}>
                  <TabView
                    datasets={[
                      {
                        element: (
                          <>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-participant-name'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage('PARTICIPANT_NAME')}
                              name={'participant_name'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              required
                              value={formik.values.participant_name}
                              error={formik.errors.participant_name}
                              style={{
                                backgroundColor: `rgb(var(--background-color))`,
                              }}
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-participant-joined'],
                                styles['kut-input-form'],
                              ])}
                              type={'date'}
                              heading={getStringByLanguage('JOINED')}
                              name={'joined'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              required
                              value={formik.values.joined.slice(0, 10)}
                              error={formik.errors.joined}
                              style={{
                                backgroundColor: `rgb(var(--background-color))`,
                              }}
                            />
                            <div
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-input-form'],
                                styles['kut-radio-group'],
                                styles['kut-form-type'],
                              ])}
                            >
                              <div>
                                <p>{getStringByLanguage('PERSON_TYPE')}</p>
                                <RadioGroup
                                  value={formik.values.type}
                                  readOnly={onlyView}
                                  groupId={'type'}
                                  onChange={(value) => {
                                    formik.setFieldValue('type', value);
                                  }}
                                  itemList={personTypes}
                                />
                              </div>
                            </div>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-address'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['ADDRESS'])}
                              name={'participant_address'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_address || ''}
                              error={
                                formik.touched.participant_address &&
                                formik.errors.participant_address
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-city'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['CITY'])}
                              name={'participant_city'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_city || ''}
                              error={
                                formik.touched.participant_city &&
                                formik.errors.participant_city
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-country'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['COUNTRY'])}
                              name={'participant_country'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_country || ''}
                              error={formik.errors.participant_country}
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-postcode'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['POSTCODE'])}
                              name={'participant_postalcode'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_postalcode || ''}
                              error={
                                formik.touched.participant_postalcode &&
                                formik.errors.participant_postalcode
                              }
                            />
                          </>
                        ),
                      },
                      {
                        element: (
                          <>
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-idn'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['ID_NUMBER'])}
                              name={'participant_idn'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_idn || ''}
                              error={
                                formik.touched.participant_idn &&
                                formik.errors.participant_idn
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-office-idn'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage([
                                'OFFICE_ID_NUMBER',
                              ])}
                              name={'participant_office_idn'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_office_idn || ''}
                              error={
                                formik.touched.participant_office_idn &&
                                formik.errors.participant_office_idn
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-dso'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['VAT'])}
                              name={'participant_vat'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_vat || ''}
                              error={
                                formik.touched.participant_vat &&
                                formik.errors.participant_vat
                              }
                            />
                            <InputForm
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-nace'],
                                styles['kut-input-form'],
                              ])}
                              heading={getStringByLanguage(['NACE'])}
                              name={'participant_nace'}
                              onChange={formik.handleChange}
                              readOnly={onlyView}
                              value={formik.values.participant_nace || ''}
                              error={
                                formik.touched.participant_nace &&
                                formik.errors.participant_nace
                              }
                            />
                            <LegalStructureCode
                              className={cn([
                                styles['kut-form-element'],
                                styles['kut-form-legal-structure-code'],
                                styles['kut-input-form'],
                              ])}
                              name="participant_lscn"
                              heading={getStringByLanguage([
                                'LEGAL_STRUCTURE_CODE',
                              ])}
                              onChange={({ lscn, lscs }) => {
                                formik.setFieldValue('participant_lscn', lscn);
                                formik.setFieldValue('participant_lscs', lscs);
                              }}
                              readOnly={onlyView}
                              value={{
                                lscn: formik.values.participant_lscn,
                                lscs: formik.values.participant_lscs,
                              }}
                              error={
                                (formik.touched.participant_lscn &&
                                  formik.errors.participant_lscn) ||
                                (formik.touched.participant_lscn &&
                                  formik.errors.participant_lscs)
                              }
                            />
                          </>
                        ),
                      },
                    ]}
                    {...tabView}
                  />
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  {tabView.status.activeTab === 0 ? (
                    <>
                      <Button
                        outline
                        type={BUTTON_TYPE.INFO}
                        style={{
                          padding: 10,
                          fontSize: '1em',
                          visibility: 'hidden',
                          transition: 'none',
                        }}
                      >
                        <IoArrowBackOutline />
                      </Button>
                      <div className={cn([styles['kut-form-submit']])}>
                        <Button
                          onClick={async () => {
                            const result = await formik.validateForm();
                            const fields = [
                              'participant_name',
                              'joined',
                              'type',
                              'participant_address',
                              'participant_city',
                              'participant_country',
                              'participant_postalcode',
                            ];
                            fields.forEach((field) =>
                              formik.setFieldTouched(field, true),
                            );
                            if (
                              fields.reduce<boolean>(
                                (check, field) => check || !!result[field],
                                false,
                              )
                            ) {
                              return;
                            }
                            tabView.status.switchTab(1);
                          }}
                        >
                          {getStringByLanguage('CONTINUE')}
                        </Button>
                        <Button
                          type={BUTTON_TYPE.DANGER}
                          onClick={() => updateModal.hide()}
                        >
                          {getStringByLanguage('CANCEL')}
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Button
                        outline
                        type={BUTTON_TYPE.INFO}
                        onClick={() => tabView.status.switchTab(0)}
                        style={{
                          padding: 10,
                          fontSize: '1em',
                        }}
                      >
                        <IoArrowBackOutline />
                      </Button>
                      <div className={cn([styles['kut-form-submit']])}>
                        <Button onClick={() => formik.handleSubmit()}>
                          {getStringByLanguage('SAVE_UPDATES')}
                        </Button>
                        <Button
                          type={BUTTON_TYPE.DANGER}
                          onClick={() => updateModal.hide()}
                        >
                          {getStringByLanguage('CANCEL')}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...deleteModal}>
          {(formik) => (
            <form
              className={cn([
                styles['kut-modal-form'],
                styles['kut-delete-form'],
              ])}
            >
              <p dangerouslySetInnerHTML={{ __html: modalText }} />
              <hr />
              <div className={cn([styles['kut-form-actions']])}>
                <Button onClick={() => formik.handleSubmit()}>
                  {getStringByLanguage('DELETE')}
                </Button>
                <Button
                  type={BUTTON_TYPE.DANGER}
                  onClick={() => deleteModal.hide()}
                >
                  {getStringByLanguage('CANCEL')}
                </Button>
              </div>
            </form>
          )}
        </Modal>
      </div>
    </>
  );
};

export default Participants;
