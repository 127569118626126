import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import {
  BsGrid,
  BsTrash2,
  BsArrowDownCircle,
  BsThreeDotsVertical,
} from 'react-icons/bs';
import { AiOutlineUnorderedList } from 'react-icons/ai';
import { TbFileInvoice } from 'react-icons/tb';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Accept, useDropzone } from 'react-dropzone';
import Popup from 'reactjs-popup';
import { TableColumn } from 'react-data-table-component';
import Select from 'react-select';

import SubSidebar from '../../../../components/SubSidebar/SubSidebar';
import Modal, { useModal } from '../../../../components/Modal/Modal';
import Table from '../../../../components/Table/Table';
import { useLoadingBars } from '../../../../components/LoadingBar/LoadingBar';
import StatusSpan, {
  STATUS,
} from '../../../../components/StatusSpan/StatusSpan';
import InputForm from '../../../../components/InputForm/InputForm';
import Button, { BUTTON_TYPE } from '../../../../components/Button/Button';
import IOSSwitch from '../../../../components/IOSSwitch/IOSSwitch';
import ListView from '../../../../components/ListView/ListView';
import {
  DocumentType,
  contractColumns,
  invoiceColumns,
  documentTypes,
  filterOption,
  SelectOptionsProps,
} from '../../../Documents/Documents.helper';

import { getPersonalView } from '../../../../services/reducers/sharedReducer';
import { getLoginedUserInfo } from '../../../../services/reducers/userReducer';
import { useAppSelector, useDeviceType } from '../../../../services/hooks';

import {
  ParticipantInfo,
  getParticipants,
} from '../../../../apis/participants';
import { addUserPageLog, Gender, UserRole } from '../../../../apis/users';
import {
  DocumentInfo,
  DOCUMENT_TYPE,
  getDocuments,
  addDocument,
  updateDocument,
  deleteDocument,
  DocumentSubmit,
} from '../../../../apis/documents';

import {
  getStringByLanguage,
  isNewDocument,
  colorDict,
  getServerImageUrl,
  getDocumentUrl,
} from '../../../../utils/utils';
import { defaultAvatars } from '../../../../utils/constants';

import styles from './styles.module.scss';

const ParticipantRowComponent: FC<{
  data: { avatar: string | null; gender: Gender; participant_name: string };
}> = ({ data: { avatar, gender, participant_name } }) => (
  <div className={cn([styles['kut-select-inner-item']])}>
    <div
      className={cn([styles['kut-select-inner-item-avatar']])}
      style={{
        backgroundImage:
          (avatar && `url(${getServerImageUrl(avatar)})`) ||
          `url(${defaultAvatars[gender]})`,
      }}
    />
    <span>{participant_name}</span>
  </div>
);

const Documents: FC = () => {
  const { community_id } = useParams();
  const navigate = useNavigate();

  if (!community_id || isNaN(parseInt(community_id))) {
    navigate('/manage/communities');
    return <></>;
  }

  const { isMobile } = useDeviceType();
  const userInfo = useAppSelector(getLoginedUserInfo);
  const personalView = useAppSelector(getPersonalView);

  const [ctrlAuth, setCtrlAuth] = useState<boolean>();
  const [viewStatus, setViewStatus] = useState<number>(0);
  const [contracts, setContracts] = useState<DocumentInfo[]>([]);
  const [invoices, setInvoices] = useState<DocumentInfo[]>([]);
  const [participantList, setParticipantList] = useState<
    SelectOptionsProps<ParticipantInfo>[]
  >([]);

  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const [modalTitle, setModalTitle] = useState('');
  const [modalItem, setModalItem] = useState<DocumentInfo>();
  const [modalDeleteItems, setModalDeleteItems] = useState<
    Partial<DocumentInfo>[]
  >([]);

  const [selectedContractItems, setSelectedContractItems] = useState<{
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<DocumentInfo>[];
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] });
  const [selectedInvoiceItems, setSelectedInvoiceItems] = useState<{
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<DocumentInfo>[];
  }>({ allSelected: false, selectedCount: 0, selectedRows: [] });

  const [selectContractAll, setSelectContractAll] = useState(false);
  const [isEventFromContractAll, setIsEventFromContractAll] = useState(false);

  const [selectInvoiceAll, setSelectInvoiceAll] = useState(false);
  const [isEventFromInvoiceAll, setIsEventFromInvoiceAll] = useState(false);

  useEffect(() => {
    addUserPageLog('Document');
  }, []);

  useEffect(() => {
    init();
  }, [personalView]);

  const getDocumentList = async () => {
    const { data } = (
      await getDocuments({
        community_id: Number(community_id),
        participant_id: personalView ? userInfo?.participant?.id : undefined,
      })
    ).data;
    if (!data) {
      return;
    }

    const [documents] = data;

    setContracts(
      documents.filter(
        (document) => document.document_type === DOCUMENT_TYPE.Contract,
      ),
    );
    setInvoices(
      documents.filter(
        (document) => document.document_type === DOCUMENT_TYPE.Invoice,
      ),
    );
  };

  const getParticipantList = async () => {
    const { data } = (
      await getParticipants({ community_id: Number(community_id) })
    ).data;
    if (!data) {
      return;
    }

    const [participants] = data;

    setParticipantList(
      participants.map((value) => ({
        value,
        label: (
          <ParticipantRowComponent
            data={{
              gender: value.user.gender,
              avatar: value.user.avatar,
              participant_name: value.participant_name,
            }}
          />
        ),
      })),
    );
  };

  const init = async () => {
    await getDocumentList();
    if (userInfo?.role === UserRole.Participant || personalView) {
      setCtrlAuth(false);
    } else {
      await getParticipantList();
      setCtrlAuth(true);
    }
  };

  const contractCtrl: TableColumn<DocumentInfo>[] = contractColumns.concat([
    {
      name: '',
      width: '2.813rem',
      compact: true,
      style: {
        cursor: 'pointer',
      },
      cell: (data) => {
        return <ActionComponent data={data} />;
      },
    },
  ]);

  const invoiceCtrl: TableColumn<DocumentInfo>[] = invoiceColumns.concat([
    {
      name: '',
      width: '2.813rem',
      compact: true,
      style: {
        cursor: 'pointer',
      },
      cell: (data) => {
        return <ActionComponent data={data} />;
      },
    },
  ]);

  const ActionComponent: FC<{ data: DocumentInfo }> = ({ data }) => (
    <Popup
      className="action-popup"
      trigger={
        <div>
          <BsThreeDotsVertical />
        </div>
      }
      position={['bottom right']}
      arrow={false}
      contentStyle={{
        width: 'initial',
        backgroundColor: 'rgb(var(--content-background-color))',
        borderColor: 'rgb(var(--background-color))',
        padding: '5px 0px',
        boxShadow: '2px 2px 5px 0px rgba(var(--text-color), .2)',
      }}
    >
      <div className={styles['action-item-list']}>
        <span
          className={cn([styles['action-button-update']])}
          onClick={() => updateRow(data)}
        >
          {getStringByLanguage('UPDATE')}
        </span>
        <span
          className={cn([styles['action-button-delete']])}
          onClick={() => deleteRow(data)}
        >
          {getStringByLanguage('DELETE')}
        </span>
      </div>
    </Popup>
  );

  const handleDownloadAll = () => {};

  const onViewChange = (index: number) => {
    setViewStatus(index);
    setSelectedContractItems({
      allSelected: false,
      selectedCount: 0,
      selectedRows: [],
    });
    setSelectedInvoiceItems({
      allSelected: false,
      selectedCount: 0,
      selectedRows: [],
    });
    setSelectContractAll(false);
    setSelectInvoiceAll(false);
  };

  const onContractItemSelected = (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<DocumentInfo>[];
  }) => {
    setSelectedContractItems(selected);
  };

  const onInvoiceItemSelected = (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: Partial<DocumentInfo>[];
  }) => {
    setSelectedInvoiceItems(selected);
  };

  const onDeleteSelected = () => {
    if (
      !selectedContractItems.selectedCount &&
      !selectedInvoiceItems.selectedCount
    ) {
      return;
    }
    setModalTitle(
      `${getStringByLanguage(
        'ARE_YOU_SURE_WANT_TO_DELETE',
      )} <b>${`${getStringByLanguage('SELECTED').toLowerCase()} ${
        selectedContractItems.selectedCount + selectedInvoiceItems.selectedCount
      } ${getStringByLanguage('DEVICES').toLowerCase()}`}</b>?`,
    );
    setModalDeleteItems(
      selectedContractItems.selectedRows.concat(
        selectedInvoiceItems.selectedRows,
      ),
    );
    deleteModal.show();
  };

  const onUpdate = async (document: Partial<DocumentSubmit>) => {
    showLoadingBar();
    try {
      await updateDocument({
        community_id: community_id,
        participant_id: document.participant_id,
        data: document,
      });
      toast.success(getStringByLanguage('SUCCESSFULLY_UPDATED'));
      updateModal.hide();
      init();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onDelete = async (params: { forever?: boolean }) => {
    showLoadingBar();
    modalDeleteItems.map(async (document) => {
      if (!document.id) {
        throw getStringByLanguage('REQUEST_ERROR');
      }
      await deleteDocument(
        community_id,
        document.participant_id,
        document.id,
        params.forever,
      );
      init();
    });

    try {
      toast.success(getStringByLanguage('SUCCESSFULLY_DELETED'));
      setSelectedContractItems({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
      });
      setSelectedInvoiceItems({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
      });
      deleteModal.hide();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const onCreateNew = () => {
    createModal.show();
  };

  const onCreate = async (document: Partial<DocumentSubmit>) => {
    showLoadingBar();
    try {
      await addDocument({
        community_id: community_id,
        participant_id: document.participant_id,
        data: document,
      });
      toast.success(getStringByLanguage('SUCCESSFULLY_CREATED'));
      createModal.hide();
      init();
    } catch (error) {
      console.log(error);
    }
    hideLoadingBar();
  };

  const updateRow = (document: DocumentInfo) => {
    setModalItem(document);
    updateModal.show();
  };

  const deleteRow = (document: DocumentInfo) => {
    setModalTitle(
      `${getStringByLanguage('ARE_YOU_SURE_WANT_TO_DELETE')} <b>${
        document.document_name
      }</b> ${getStringByLanguage('DOCUMENT').toLowerCase()}?`,
    );
    setModalDeleteItems([document]);
    deleteModal.show();
  };

  const createModal = useModal<Partial<DocumentSubmit>>({
    onSubmit: onCreate,
    initialValues: {
      participant_id: userInfo?.participant?.id,
      document_name: '',
      document_id: '',
      document_type: DOCUMENT_TYPE.Contract,
      document_status: false,
      documents: [],
    },
    validationSchema: Yup.object().shape({
      document_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      document_id: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      document_type: Yup.string()
        .oneOf([DOCUMENT_TYPE.Contract, DOCUMENT_TYPE.Invoice])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      document_status: Yup.boolean().notRequired(),
      documents: Yup.array()
        .min(1, getStringByLanguage('THIS_FIELD_IS_REQUIRED'))
        .max(1)
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED'))
        .test('File type', 'Invalid file type.', (files: File[] = []) => {
          return (
            files.findIndex(
              (file) =>
                file.type !== 'application/pdf' &&
                !file.type.startsWith('image/'),
            ) === -1
          );
        }),
    }),
  });

  const updateModal = useModal<Partial<DocumentSubmit>>({
    onSubmit: onUpdate,
    initialValues: { ...modalItem, documents: [] } || {},
    validationSchema: Yup.object().shape({
      document_name: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      document_id: Yup.string().required(
        getStringByLanguage('THIS_FIELD_IS_REQUIRED'),
      ),
      document_type: Yup.string()
        .oneOf([DOCUMENT_TYPE.Invoice, DOCUMENT_TYPE.Contract])
        .required(getStringByLanguage('THIS_FIELD_IS_REQUIRED')),
      document_status: Yup.boolean().notRequired(),
      documents: Yup.array()
        .max(1)
        .notRequired()
        .test(
          'File type',
          getStringByLanguage('FILE_FORMAT_ERROR'),
          (files: File[] = []) => {
            return (
              files.findIndex(
                (file) =>
                  file.type !== 'application/pdf' &&
                  !file.type.startsWith('image/'),
              ) === -1
            );
          },
        ),
    }),
  });

  const deleteModal = useModal<{ forever?: boolean }>({
    onSubmit: onDelete,
    initialValues: {
      forever: false,
    },
    validationSchema: Yup.object().shape({
      forever: Yup.boolean().required(),
    }),
  });

  const viewType = isMobile || viewStatus === 1 ? true : false;

  return (
    <>
      <SubSidebar communityId={community_id} />
      <div className={styles['kut-component-body']}>
        <div className={styles['kut-list-component']}>
          {ctrlAuth === true && (
            <div className={styles['kut-component-actions']}>
              <Button
                className={cn([
                  styles['kut-component-buttons'],
                  styles['kut-buttons-large'],
                ])}
                outline={true}
                onClick={onCreateNew}
              >
                + {getStringByLanguage('CREATE_NEW')}
              </Button>
              <div style={{ display: 'flex', gap: '1em' }}>
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-large'],
                    styles['kut-component-button-delete'],
                    selectedContractItems.selectedCount ||
                    selectedInvoiceItems.selectedCount
                      ? styles['kut-active']
                      : '',
                  ])}
                  type={BUTTON_TYPE.DANGER}
                  onClick={onDeleteSelected}
                >
                  {getStringByLanguage('DELETE_SELECTED')}
                </Button>
                <Button
                  className={cn([
                    styles['kut-component-buttons'],
                    styles['kut-buttons-small'],
                    styles['kut-component-button-delete'],
                    selectedContractItems.selectedCount ||
                    selectedInvoiceItems.selectedCount
                      ? styles['kut-active']
                      : '',
                  ])}
                  type={BUTTON_TYPE.DANGER}
                  onClick={onDeleteSelected}
                >
                  <BsTrash2 />
                </Button>
              </div>
            </div>
          )}
          <div className={styles['kut-list-component-header']}>
            <span>{getStringByLanguage('COMMUNITY_CONTRACTS')}</span>
            <div
              className={cn([
                styles['kut-component-select-all'],
                !viewType ? styles['hide'] : '',
              ])}
            >
              <input
                type="checkbox"
                checked={selectContractAll}
                onChange={(event) => {
                  const status = event.target.checked;
                  setSelectContractAll(status);
                  setIsEventFromContractAll(true);
                }}
                ref={(input) => {
                  if (input) {
                    const status = selectContractAll;
                    if (!status && selectedContractItems.selectedCount) {
                      input.indeterminate = true;
                      return;
                    }
                    input.indeterminate = false;
                    status
                      ? setSelectContractAll(true)
                      : setSelectContractAll(false);
                  }
                }}
              />
              <span>{getStringByLanguage('SELECT_CONTRACTS')}</span>
            </div>
            {!isMobile && (
              <div className={styles['kut-switch-view']}>
                <span>{getStringByLanguage('SWITCH_VIEW')}</span>
                <BsGrid
                  className={cn([viewType && styles['kut-active']])}
                  onClick={() => onViewChange(1)}
                />
                <AiOutlineUnorderedList
                  className={cn([!viewType && styles['kut-active']])}
                  onClick={() => onViewChange(0)}
                />
              </div>
            )}
          </div>
          <div className={styles['kut-list-component-content']}>
            {viewType === true ? (
              <>
                <ListView
                  selectAll={selectContractAll}
                  setSelectAll={setSelectContractAll}
                  isEventFromAll={isEventFromContractAll}
                  setIsEventFromAll={setIsEventFromContractAll}
                  onItemSelected={onContractItemSelected}
                  data={contracts}
                  ChildComponent={({
                    onChange,
                    checked,
                    data,
                  }: {
                    onChange: (status: boolean) => void;
                    checked: boolean;
                    data: DocumentInfo;
                  }) => {
                    const type = DocumentType.Contract;
                    const status = data.document_status
                      ? type === DocumentType.Contract
                        ? STATUS.PRIMARY
                        : STATUS.SUCCESS
                      : STATUS.DANGER;
                    const text = data.document_status
                      ? type === DocumentType.Contract
                        ? getStringByLanguage('SIGNED')
                        : getStringByLanguage('PAID')
                      : type === DocumentType.Contract
                      ? getStringByLanguage('NOT_SIGNED_YET')
                      : getStringByLanguage('NOT_PAID_YET');
                    return (
                      <div className={styles['kut-list-item']}>
                        <div
                          className={cn([
                            styles['kut-list-item-heading'],
                            isNewDocument(data.document_create_on)
                              ? styles['kut-new-data']
                              : '',
                          ])}
                        >
                          <div className={cn([styles['kut-list-item-name']])}>
                            <TbFileInvoice /> <span>{data.document_name}</span>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              checked={checked}
                              onChange={(event) =>
                                onChange(event.target.checked)
                              }
                            />
                            {ctrlAuth === true && (
                              <ActionComponent data={data} />
                            )}
                          </div>
                        </div>
                        <div className={styles['kut-list-item-content']}>
                          <StatusSpan {...{ status, text }} />
                          <div className={styles['kut-list-item-actions']}>
                            <a
                              target={`_blank`}
                              href={getDocumentUrl(data)}
                              className={`${styles['kut-list-item-actions']}`}
                            >
                              <BsArrowDownCircle />
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
              </>
            ) : (
              <div className={styles['kut-list-component-table']}>
                <Table
                  pagination={false}
                  selectableRows
                  onSelectedRowsChange={onContractItemSelected}
                  data={contracts}
                  columns={ctrlAuth === true ? contractCtrl : contractColumns}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles['kut-list-component']}>
          <div className={styles['kut-list-component-header']}>
            {getStringByLanguage('ENERGY_BILLS')}
            <div className={styles['kut-action-group']}>
              {ctrlAuth === true && (
                <>
                  <div
                    className={cn([
                      styles['kut-component-select-all'],
                      !viewType ? styles['hide'] : '',
                    ])}
                  >
                    <input
                      type="checkbox"
                      checked={selectInvoiceAll}
                      onChange={(event) => {
                        const status = event.target.checked;
                        setSelectInvoiceAll(status);
                        setIsEventFromInvoiceAll(true);
                      }}
                      ref={(input) => {
                        if (input) {
                          const status = selectInvoiceAll;
                          if (!status && selectedInvoiceItems.selectedCount) {
                            input.indeterminate = true;
                            return;
                          }
                          input.indeterminate = false;
                          status
                            ? setSelectInvoiceAll(true)
                            : setSelectInvoiceAll(false);
                        }
                      }}
                    />
                    <span>{getStringByLanguage('SELECT_INVOICES')}</span>
                  </div>
                  <div className={styles['kut-download-all']}>
                    <BsArrowDownCircle onClick={handleDownloadAll} />
                    <span>{getStringByLanguage('DOWNLOAD_ALL_INVOICES')}</span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles['kut-list-component-content']}>
            {viewType === true ? (
              <>
                <ListView
                  selectAll={selectInvoiceAll}
                  setSelectAll={setSelectInvoiceAll}
                  isEventFromAll={isEventFromInvoiceAll}
                  setIsEventFromAll={setIsEventFromInvoiceAll}
                  onItemSelected={onInvoiceItemSelected}
                  data={invoices}
                  ChildComponent={({
                    onChange,
                    checked,
                    data,
                  }: {
                    onChange: (status: boolean) => void;
                    checked: boolean;
                    data: DocumentInfo;
                  }) => {
                    const type = DocumentType.Invoice;
                    const status = data.document_status
                      ? type === DocumentType.Invoice
                        ? STATUS.SUCCESS
                        : STATUS.PRIMARY
                      : STATUS.DANGER;
                    const text = data.document_status
                      ? type === DocumentType.Invoice
                        ? getStringByLanguage('PAID')
                        : getStringByLanguage('SIGNED')
                      : type === DocumentType.Invoice
                      ? getStringByLanguage('NOT_PAID_YET')
                      : getStringByLanguage('NOT_SIGNED_YET');
                    return (
                      <div className={styles['kut-list-item']}>
                        <div
                          className={cn([
                            styles['kut-list-item-heading'],
                            isNewDocument(data.document_create_on)
                              ? styles['kut-new-data']
                              : '',
                          ])}
                        >
                          <div className={cn([styles['kut-list-item-name']])}>
                            <TbFileInvoice /> <span>{data.document_name}</span>
                          </div>
                          <div>
                            <input
                              type="checkbox"
                              checked={checked}
                              onChange={(event) =>
                                onChange(event.target.checked)
                              }
                            />
                            {ctrlAuth === true && (
                              <ActionComponent data={data} />
                            )}
                          </div>
                        </div>
                        <div className={styles['kut-list-item-content']}>
                          <StatusSpan {...{ status, text }} />
                          <div className={styles['kut-list-item-actions']}>
                            <a
                              target={`_blank`}
                              href={getDocumentUrl(data)}
                              className={`${styles['kut-list-item-actions']}`}
                            >
                              <BsArrowDownCircle />
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
              </>
            ) : (
              <div className={styles['kut-list-component-table']}>
                <Table
                  pagination={false}
                  selectableRows
                  onSelectedRowsChange={onInvoiceItemSelected}
                  data={invoices}
                  columns={ctrlAuth === true ? invoiceCtrl : invoiceColumns}
                />
              </div>
            )}
          </div>
        </div>
        <Modal {...createModal}>
          {(formik) => {
            const fileFormats = [
              'image/bmp',
              'image/jpeg',
              'image/png',
              'application/pdf',
            ];
            const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
              maxFiles: 1,
              accept: fileFormats.reduce<Accept>((accept, format) => {
                accept[format] = [];
                return accept;
              }, {}),
              onDrop: (files) => {
                formik.setFieldValue('documents', files);
              },
              onFileDialogCancel: () => {
                formik.setFieldValue('documents', []);
              },
              multiple: false,
            });

            return (
              <form
                className={cn([
                  styles['kut-modal-form'],
                  styles['kut-create-form'],
                ])}
              >
                <p className={cn([styles['kut-title']])}>
                  {getStringByLanguage(['CREATE', 'DOCUMENT'])}
                </p>
                <hr />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-participant'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('PARTICIPANT')}
                    </span>
                    <Select
                      className={`${styles['input-form-content']}`}
                      options={participantList}
                      filterOption={(candidate, input) => {
                        if (input) {
                          return (
                            candidate.data.value.participant_name
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) !== -1
                          );
                        }
                        return true;
                      }}
                      name={`participant_id`}
                      isSearchable
                      onChange={(v) => {
                        formik.setFieldValue('participant_id', v?.value.id);
                      }}
                      value={participantList.find(
                        (value) =>
                          value.value.id === formik.values.participant_id,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <InputForm
                  className={cn([
                    styles['kut-form-document-name'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DOCUMENT_NAME')}
                  name={'document_name'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.document_name}
                  error={
                    formik.touched.document_name && formik.errors.document_name
                  }
                />
                <InputForm
                  className={cn([
                    styles['kut-form-document-id'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DOCUMENT_ID')}
                  name={'document_id'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.document_id}
                  error={
                    formik.touched.document_id && formik.errors.document_id
                  }
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-type'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('DOCUMENT_TYPE')}
                    </span>
                    <Select
                      className={`${styles['input-form-content']}`}
                      options={documentTypes}
                      filterOption={filterOption}
                      name={`document_type`}
                      isSearchable
                      onChange={(v) => {
                        formik.setFieldValue('document_type', v?.value);
                      }}
                      value={documentTypes.find(
                        (value) => value.value === formik.values.document_type,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-status'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('DOCUMENT_STATUS')}
                    </span>
                    <div className={cn([styles['kut-form-document-switch']])}>
                      <IOSSwitch
                        name={'document_status'}
                        checked={formik.values.document_status}
                        onChange={formik.handleChange}
                      />
                      <span className={cn([styles['input-form-content']])}>
                        {formik.values.document_type === DOCUMENT_TYPE.Invoice
                          ? formik.values.document_status
                            ? getStringByLanguage('PAID')
                            : getStringByLanguage('NOT_PAID_YET')
                          : formik.values.document_status
                          ? getStringByLanguage('SIGNED')
                          : getStringByLanguage('NOT_SIGNED_YET')}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-item'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('DOCUMENT')}
                    </span>
                    <div
                      {...getRootProps({
                        className: cn([styles['input-form-content']]),
                      })}
                    >
                      <input {...getInputProps()} />
                      <p>
                        {acceptedFiles.length
                          ? acceptedFiles[0].name
                          : getStringByLanguage(
                              'DRAG_AND_DROP_A_FILE_HERE_OR_CLICK_TO_SELECT_FILE',
                            )}
                      </p>
                    </div>
                  </div>
                  {formik.touched.documents && formik.errors.documents && (
                    <p>{formik.errors.documents as string}</p>
                  )}
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  <Button onClick={() => formik.handleSubmit()}>
                    {getStringByLanguage('CREATE')}
                  </Button>
                  <Button
                    type={BUTTON_TYPE.DANGER}
                    onClick={() => createModal.hide()}
                  >
                    {getStringByLanguage('CANCEL')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...updateModal}>
          {(formik) => {
            const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
              maxFiles: 1,
              accept: {
                'image/bmp': [],
                'image/jpeg': [],
                'image/png': [],
                'application/pdf': [],
              },
              onDrop: (files) => {
                formik.setFieldValue('documents', files);
              },
              onFileDialogCancel: () => {
                formik.setFieldValue('documents', []);
              },
              multiple: false,
            });

            return (
              <form
                className={cn([
                  styles['kut-modal-form'],
                  styles['kut-update-form'],
                ])}
              >
                <p className={cn([styles['kut-title']])}>
                  {getStringByLanguage(['UPDATE', 'DOCUMENT'])}
                </p>
                <hr />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-participant'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('PARTICIPANT')}
                    </span>
                    <Select
                      className={`${styles['input-form-content']}`}
                      options={participantList}
                      filterOption={(candidate, input) => {
                        if (input) {
                          return (
                            candidate.data.value.participant_name
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) !== -1
                          );
                        }
                        return true;
                      }}
                      name={`participant_id`}
                      isSearchable
                      value={participantList.find(
                        (value) =>
                          value.value.id === formik.values.participant_id,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <InputForm
                  className={cn([
                    styles['kut-form-document-name'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DOCUMENT_NAME')}
                  name={'document_name'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.document_name}
                  error={
                    formik.touched.document_name && formik.errors.document_name
                  }
                />
                <InputForm
                  className={cn([
                    styles['kut-form-document-id'],
                    styles['kut-input-form'],
                  ])}
                  heading={getStringByLanguage('DOCUMENT_ID')}
                  name={'document_id'}
                  onChange={formik.handleChange}
                  required
                  value={formik.values.document_id}
                  error={
                    formik.touched.document_id && formik.errors.document_id
                  }
                />
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-type'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('DOCUMENT_TYPE')}
                    </span>
                    <Select
                      className={`${styles['input-form-content']}`}
                      options={documentTypes}
                      filterOption={filterOption}
                      name={`document_type`}
                      isSearchable
                      onChange={(v) => {
                        formik.setFieldValue('document_type', v?.value);
                      }}
                      value={documentTypes.find(
                        (value) => value.value === formik.values.document_type,
                      )}
                      maxMenuHeight={200}
                      styles={{
                        control: (baseStyle) => ({
                          ...baseStyle,
                          boxShadow: 'none',
                          border: 'none',
                          color: `rgb(var(--text-color))`,
                          padding: '0.5em 0.75em',
                          backgroundColor: 'rgb(var(--background-color))',
                          borderRadius: '10px',
                        }),
                        input: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        placeholder: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        singleValue: (baseStyle) => ({
                          ...baseStyle,
                          color: `rgb(var(--text-color))`,
                        }),
                        indicatorSeparator: (baseStyle) => ({
                          ...baseStyle,
                          width: 0,
                        }),
                        dropdownIndicator: (baseStyle) => ({
                          ...baseStyle,
                          padding: '0px',
                          marginRight: '0.5em',
                          color: colorDict['color-black-strong-light'],
                        }),
                        menu: (baseStyle) => ({
                          ...baseStyle,
                          backgroundColor: `rgb(var(--background-color))`,
                        }),
                        option: (baseStyle, state) => {
                          return {
                            ...baseStyle,
                            backgroundColor: state.isSelected
                              ? `rgba(var(--text-color), 1)`
                              : state.isFocused
                              ? `rgba(var(--text-color), .3)`
                              : 'inherit',
                            color: state.isSelected
                              ? `rgb(var(--content-background-color))`
                              : 'inherit',
                            cursor: 'pointer',
                            transition: 'all .3s',
                          };
                        },
                      }}
                    />
                  </div>
                </div>
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-status'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('DOCUMENT_STATUS')}
                    </span>
                    <div className={cn([styles['kut-form-document-switch']])}>
                      <IOSSwitch
                        name={'document_status'}
                        checked={formik.values.document_status}
                        onChange={formik.handleChange}
                      />
                      <span className={cn([styles['input-form-content']])}>
                        {formik.values.document_type === DOCUMENT_TYPE.Invoice
                          ? formik.values.document_status
                            ? getStringByLanguage('PAID')
                            : getStringByLanguage('NOT_PAID_YET')
                          : formik.values.document_status
                          ? getStringByLanguage('SIGNED')
                          : getStringByLanguage('NOT_SIGNED_YET')}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className={cn([
                    styles['kut-input-form'],
                    styles['kut-form-document-item'],
                  ])}
                >
                  <div>
                    <span className={cn([styles['input-form-title']])}>
                      {getStringByLanguage('DOCUMENT')}
                    </span>
                    <div
                      {...getRootProps({
                        className: cn([styles['input-form-content']]),
                      })}
                    >
                      <input {...getInputProps()} />
                      <p>
                        {acceptedFiles.length
                          ? acceptedFiles[0].name
                          : formik.values.document_url}
                      </p>
                    </div>
                  </div>
                  {formik.touched.documents && formik.errors.documents && (
                    <p>{formik.errors.documents as string}</p>
                  )}
                </div>
                <hr />
                <div className={cn([styles['kut-form-actions']])}>
                  <Button onClick={() => formik.handleSubmit()}>
                    {getStringByLanguage('SAVE_UPDATES')}
                  </Button>
                  <Button
                    type={BUTTON_TYPE.DANGER}
                    onClick={() => updateModal.hide()}
                  >
                    {getStringByLanguage('CANCEL')}
                  </Button>
                </div>
              </form>
            );
          }}
        </Modal>
        <Modal {...deleteModal}>
          {(formik) => (
            <form
              className={cn([
                styles['kut-modal-form'],
                styles['kut-delete-form'],
              ])}
            >
              <p dangerouslySetInnerHTML={{ __html: modalTitle }} />
              <hr />
              <div className={cn([styles['kut-form-delete-forever']])}>
                <span
                  style={{
                    color: `rgb(var(--text-color))`,
                  }}
                >
                  {getStringByLanguage('DELETE_FOREVER')}?
                </span>
                <IOSSwitch
                  name={'forever'}
                  checked={formik.values.forever}
                  onChange={formik.handleChange}
                />
              </div>
              <p
                className={cn([styles['kut-form-delete-forever-description']])}
              >
                {getStringByLanguage('DELETE_FOREVER_DESCRIPTION')}
              </p>
              <hr />
              <div className={cn([styles['kut-form-actions']])}>
                <Button onClick={() => formik.handleSubmit()}>
                  {getStringByLanguage('DELETE')}
                </Button>
                <Button
                  type={BUTTON_TYPE.DANGER}
                  onClick={() => deleteModal.hide()}
                >
                  {getStringByLanguage('CANCEL')}
                </Button>
              </div>
            </form>
          )}
        </Modal>
      </div>
    </>
  );
};

export default Documents;
