import { FC, useEffect, useState } from 'react';
import { AiOutlineSetting } from 'react-icons/ai';
import { FiBarChart } from 'react-icons/fi';
import cn from 'classnames';
import DatePicker from 'react-datepicker';
import * as DateFNS from 'date-fns';

import { useLoadingBars } from '../../components/LoadingBar/LoadingBar';
import SVGFromServer, {
  ImageColors,
} from '../../components/SVGFromServer/SVGFromServer';
import {
  AggregatedTotalProps,
  ListDataProps,
  ListItemProps,
  NavbarComponent,
  getListAndValue,
} from './Devices.helper';

import {
  numUtils,
  colorDict,
  getServerImageUrl,
  getStringByLanguage,
  getDate,
} from '../../utils/utils';
import {
  useAppDispatch,
  useAppSelector,
  useDeviceType,
} from '../../services/hooks';
import {
  getPersonalView,
  getSelectedDate,
  getSelectedRange,
  setSelectedDate,
  setSelectedRange,
} from '../../services/reducers/sharedReducer';
import { getLoginedUserInfo } from '../../services/reducers/userReducer';
import { DeviceInfo, getDevices } from '../../apis/devices';
import { DeviceMode } from '../../apis/deviceTypes';
import {
  TimeRange,
  getStreamsAggregated,
  isAggregatedByDevice,
} from '../../apis/streams';
import { addUserPageLog, UserRole } from '../../apis/users';

import ArrowGif from '../../assets/images/arrow.gif';
import { ReactComponent as RoundRect4SVG } from '../../assets/images/round-rect-4.svg';

import 'react-datepicker/dist/react-datepicker.css';
import styles from './styles.module.scss';

const iconPaths = [
  '',
  '/uploads/svgs/1 battery connected.svg',
  '/uploads/svgs/2 battery disconnected.svg',
  '/uploads/svgs/3 ev chargers.svg',
  '/uploads/svgs/4 grid.svg',
  '/uploads/svgs/5 heat pump.svg',
  '/uploads/svgs/6 solar panels.svg',
  '/uploads/svgs/7 wind turbines.svg',
];

const ListItem: FC<ListItemProps> = ({ data, mode, reverse, isDesktop }) => {
  if (!data) {
    return <div className={cn([styles['kut-list-item-container']])}></div>;
  }

  const { participant_name, ean_code, device_name, value, unit, deleted_at } =
    data;

  const status = value > 0;

  const color = value
    ? mode === DeviceMode.Producer
      ? ImageColors.WARNING
      : ImageColors.SUCCESS
    : ImageColors.DEFAULT;

  return (
    <>
      {isDesktop && (
        <div
          className={cn([
            styles['kut-list-item-container'],
            reverse && 'kut-direction-row-reverse',
            reverse && 'kut-text-right',
            reverse && styles['reverse'],
            deleted_at && styles['removed'],
          ])}
        >
          <span className={cn([styles['banner']])}>
            {getStringByLanguage('REMOVED')}
          </span>
          <div
            className={cn([
              styles['kut-list-item'],
              reverse && 'kut-direction-row-reverse',
            ])}
          >
            {data.device_type_image ? (
              <SVGFromServer
                uri={getServerImageUrl(data.device_type_image)}
                status={status}
                color={color}
              />
            ) : (
              <img
                className={styles['kut-list-item-icon']}
                src={iconPaths[1]}
              />
            )}
            <div className={styles['kut-list-item-content']}>
              <div className={styles['kut-list-item-title']}>
                <p>{device_name}</p>
                <p>{' (' + participant_name + ')'}</p>
              </div>
              <p
                className={styles['kut-list-item-value']}
                style={{
                  color: colorDict[color || 'no-color'],
                  opacity: value ? 1 : 0.3,
                }}
              >
                {value} {unit}
              </p>
              <p className={styles['kut-list-item-ean-code']}>{ean_code}</p>
            </div>
          </div>
          <div className={styles['kut-list-item-arrow']}>
            <img src={ArrowGif} alt="arrow" className={styles['arrow-image']} />
          </div>
        </div>
      )}
      {!isDesktop && (
        <div
          className={cn([
            styles['kut-list-item-container'],
            reverse && 'kut-direction-row-reverse',
            reverse && 'kut-text-right',
            reverse && styles['reverse'],
            deleted_at && styles['removed'],
          ])}
        >
          <span className={cn([styles['banner']])}>
            {getStringByLanguage('REMOVED')}
          </span>
          <div className={styles['kut-list-item']}>
            <div
              className={cn([
                styles['kut-list-item-content'],
                reverse && 'kut-items-flex-end',
              ])}
            >
              <div className={cn([styles['kut-list-item-title']])}>
                <p>{device_name}</p>
                <p>{' (' + participant_name + ')'}</p>
              </div>
              {data.device_type_image ? (
                <SVGFromServer
                  uri={getServerImageUrl(data.device_type_image)}
                  status={status}
                  color={color}
                />
              ) : (
                <img
                  className={styles['kut-list-item-icon']}
                  src={iconPaths[1]}
                />
              )}
              <p
                className={styles['kut-list-item-value']}
                style={{
                  color: colorDict[color || 'no-color'],
                  opacity: value ? 1 : 0.3,
                }}
              >
                {numUtils.decimal(value)} {unit}
              </p>
              <p className={styles['kut-list-item-ean-code']}>{ean_code}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const Devices: FC = () => {
  const { isDesktop } = useDeviceType();
  const [showLoadingBar, hideLoadingBar] = useLoadingBars();
  const userInfo = useAppSelector(getLoginedUserInfo);
  const personalView = useAppSelector(getPersonalView);

  const dispatch = useAppDispatch();
  const selectedRange = useAppSelector(getSelectedRange);
  const date = useAppSelector(getSelectedDate);
  const selectedDate = new Date(date);

  const [period, setPeriod] = useState<{
    startDate: Date;
    endDate: Date;
  } | null>(null);
  const [listDataAndValue, setListDataAndValue] = useState<{
    listData: ListDataProps[];
    aggValues: AggregatedTotalProps;
  }>({
    listData: [],
    aggValues: {
      Production: {
        value: 0,
        unit: getStringByLanguage('KWH'),
      },
      Consumption: {
        value: 0,
        unit: getStringByLanguage('KWH'),
      },
    },
  });
  const [deviceList, setDeviceList] = useState<DeviceInfo[]>([]);

  const init = async () => {
    const {
      data: { data },
    } = await getDevices({
      participant_id: personalView ? userInfo?.participant?.id : undefined,
      withDeleted: true,
    });

    if (!data) {
      return;
    }

    setDeviceList(data[0]);
  };
  useEffect(() => {
    addUserPageLog('Devices');
  }, []);

  useEffect(() => {
    init();
  }, [personalView]);

  useEffect(() => {
    if (!period || !deviceList.length || !userInfo) {
      return;
    }

    showLoadingBar();

    getStreamsAggregated({
      ...period,
      participant_id:
        userInfo.role === UserRole.Community_Manager && !personalView
          ? undefined
          : userInfo.participant?.id,
    })
      .then(async ({ data: { data: streamData } }) => {
        if (!streamData) {
          throw new Error(getStringByLanguage('REQUEST_ERROR'));
        }

        const [streams] = streamData;

        if (isAggregatedByDevice(streams)) {
          setListDataAndValue(
            getListAndValue({
              deviceList,
              userInfo,
              streams,
            }),
          );
        }

        hideLoadingBar();
      })
      .catch((error) => {
        console.log(error);
        hideLoadingBar();
      });
  }, [period, deviceList]);

  useEffect(() => {
    setPeriodRange(selectedRange, selectedDate);
  }, [selectedRange, date]);

  const setPeriodRange = (selectedRange: TimeRange, current = new Date()) => {
    switch (selectedRange) {
      case TimeRange.Today:
        setPeriod({
          startDate: DateFNS.startOfDay(current),
          endDate: DateFNS.endOfDay(current),
        });
        break;
      case TimeRange.Week:
        setPeriod({
          startDate: DateFNS.startOfWeek(current),
          endDate: DateFNS.endOfWeek(current),
        });
        break;
      case TimeRange.Month:
        setPeriod({
          startDate: DateFNS.startOfMonth(current),
          endDate: DateFNS.endOfMonth(current),
        });
        break;
      case TimeRange.Year:
        setPeriod({
          startDate: DateFNS.startOfYear(current),
          endDate: DateFNS.endOfYear(current),
        });
        break;
      default:
        break;
    }
  };

  const { listData, aggValues } = listDataAndValue;

  return (
    <div
      className={cn([
        styles['kut-component-body'],
        !isDesktop && styles['kut-list-item-tablet'],
      ])}
    >
      <div className={styles['kut-component-body-content']}>
        <NavbarComponent
          className={styles['device-navbar']}
          options={[
            {
              value: TimeRange.Today,
              label: getStringByLanguage('DAY'),
            },
            {
              value: TimeRange.Week,
              label: getStringByLanguage('WEEK'),
            },
            {
              value: TimeRange.Month,
              label: getStringByLanguage('MONTH'),
            },
            {
              value: TimeRange.Year,
              label: getStringByLanguage('YEAR'),
            },
          ]}
          useArrow={{
            onPrev: (value: TimeRange) => {
              if (!period) {
                return;
              }
              if (selectedRange !== TimeRange.Custom) {
                return dispatch(
                  setSelectedDate(getDate(value, selectedDate, -1).getTime()),
                );
              }
              setPeriod({
                startDate: getDate(value, period.startDate, -1),
                endDate: period.endDate,
              });
            },
            onNext: (value: TimeRange) => {
              if (!period) {
                return;
              }
              if (selectedRange !== TimeRange.Custom) {
                return dispatch(
                  setSelectedDate(getDate(value, selectedDate, 1).getTime()),
                );
              }
              setPeriod({
                startDate: period.startDate,
                endDate: getDate(value, period.endDate, 1),
              });
            },
            component: (
              <div className={cn([styles['inside-navbar']])}>
                <DatePicker
                  selected={period?.startDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date) =>
                    date &&
                    period &&
                    (selectedRange !== TimeRange.Custom
                      ? dispatch(setSelectedDate(date.getTime()))
                      : setPeriod({
                          startDate: date,
                          endDate: period.endDate,
                        }))
                  }
                />
                <span> - </span>
                <DatePicker
                  selected={period?.endDate}
                  dateFormat={'dd/MM/yyyy'}
                  onChange={(date) =>
                    date &&
                    period &&
                    (selectedRange !== TimeRange.Custom
                      ? dispatch(setSelectedDate(date.getTime()))
                      : setPeriod({
                          startDate: period.startDate,
                          endDate: date,
                        }))
                  }
                />
              </div>
            ),
          }}
          onChange={(value: TimeRange) => {
            dispatch(setSelectedRange(value));
          }}
          value={selectedRange}
        />
        <div className={styles['kut-component-content']}>
          <div className={styles['kut-list-component']}>
            <div className={styles['kut-list-component-header']}>
              <div>
                {getStringByLanguage('SHAREABLE_PRODUCTION')}
                {isDesktop && (
                  <div className={styles['kut-header-action']}>
                    <FiBarChart />
                    <AiOutlineSetting />
                  </div>
                )}
              </div>
              <div
                className={cn([!isDesktop && styles['kut-list-align-right']])}
              >
                {getStringByLanguage('CONSUMPTION')}
                {isDesktop && (
                  <div className={styles['kut-header-action']}>
                    <FiBarChart />
                    <AiOutlineSetting />
                  </div>
                )}
              </div>
            </div>
            <div className={styles['kut-list-component-content']}>
              {
                <>
                  {listData.map((v, i) => (
                    <div key={i} className={styles['kut-list-row']}>
                      <ListItem
                        mode={DeviceMode.Producer}
                        data={v.Production}
                        isDesktop={isDesktop}
                      />
                      <ListItem
                        mode={DeviceMode.Consumer}
                        data={v.Consumption}
                        reverse
                        isDesktop={isDesktop}
                      />
                      {!isDesktop && (
                        <div
                          className={cn([
                            styles['kut-list-item-arrow'],
                            styles['kut-list-item-absolute'],
                          ])}
                        >
                          <img
                            src={ArrowGif}
                            alt="arrow"
                            className={styles['arrow-image']}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <div className={styles['kut-component-body-footer']}>
        <div className={styles['kut-footer-production']}>
          <RoundRect4SVG />
          <div className={styles['kut-footer-production-content']}>
            <p>{getStringByLanguage('TOTAL_PRODUCTION')}</p>
            <p>
              {aggValues.Production.value} {aggValues.Production.unit}
            </p>
          </div>
        </div>
        <div className={styles['kut-footer-consumption']}>
          <RoundRect4SVG />
          <div className={styles['kut-footer-consumption-content']}>
            <p>{getStringByLanguage('TOTAL_CONSUMPTION')}</p>
            <p>
              {aggValues.Consumption.value} {aggValues.Consumption.unit}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Devices;
