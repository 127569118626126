import { useState, useEffect, FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { HiOutlineGlobeAsiaAustralia } from 'react-icons/hi2';
import { HiOutlineDocumentDuplicate, HiOutlineUserGroup } from 'react-icons/hi';
import { IoSpeedometerOutline } from 'react-icons/io5';
import { BsBarChart } from 'react-icons/bs';

import styles from './style.module.scss';
import { getStringByLanguage } from '../../utils/utils';
import { MenuItemProps } from '../../layouts/Sidebar/Sidebar';

interface SubSidebarProps {
  communityId: string;
}

const SubSidebar: FC<SubSidebarProps> = ({ communityId }) => {
  const params = useLocation();
  const navigate = useNavigate();

  const [topMenuList, setTopMenuList] = useState<MenuItemProps[]>([]);
  const [panelStatus, setPanelStatus] = useState(true);

  useEffect(() => {
    setSubSidebarMenuList();
  }, []);

  const setSubSidebarMenuList = () => {
    const topMenuList: MenuItemProps[] = [];
    topMenuList.push(
      {
        icon: <HiOutlineGlobeAsiaAustralia />,
        title: getStringByLanguage('COMMUNITY'),
        path: `/community/${communityId}/community`,
      },
      {
        icon: <HiOutlineUserGroup />,
        title: getStringByLanguage('PARTICIPANTS'),
        path: `/community/${communityId}/participants`,
      },
      {
        icon: <HiOutlineDocumentDuplicate />,
        title: getStringByLanguage('DOCUMENTS'),
        path: `/community/${communityId}/documents`,
        unReadCount: 0,
        // unReadCount: docLeft,
      },
      {
        icon: <IoSpeedometerOutline />,
        title: getStringByLanguage('DEVICES'),
        path: `/community/${communityId}/devices`,
      },
      {
        icon: <BsBarChart />,
        title: getStringByLanguage('ENERGY'),
        path: `/community/${communityId}/energy`,
      },
    );
    setTopMenuList(topMenuList);
  };

  const handlePageTitle = async (menuItem: MenuItemProps) => {
    if (menuItem.action) {
      await menuItem.action();
    }
    menuItem.path && navigate(menuItem.path);
  };

  return (
    <>
      <div className={cn([styles[`subsidebar-container`]])}>
        <div className={cn([styles['sidebar-scrollable-section']])}>
          <div className={styles[`top-sidebar-section`]}>
            <div className={styles[`menu-list-wrapper`]}>
              {topMenuList.map((item, i) => {
                return (
                  <div
                    className={cn([
                      styles[`menu-list-item`],
                      params.pathname.indexOf(item.path || '') === 0
                        ? styles['active']
                        : '',
                    ])}
                    onClick={() => handlePageTitle(item)}
                    key={i}
                  >
                    <>{item.icon}</>
                    {panelStatus && (
                      <span className={styles['menu-list-item-text']}>
                        {item.title}
                      </span>
                    )}
                    {item.unReadCount && item.unReadCount > 0 ? (
                      <span className={styles[`unread-count-wrapper`]}>
                        {panelStatus && item.unReadCount}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles[`bottom-sidebar-section`]}>
            <div className={styles[`menu-list-wrapper`]}>
              {/* {bottomMenuList.map((item, i) => {
                return (
                  <div
                    className={cn([
                      styles[`menu-list-item`],
                      params.pathname.indexOf(item.path || '') === 0
                        ? 'active'
                        : '',
                    ])}
                    onClick={() => handlePageTitle(item)}
                    key={i}
                  >
                    <>{item.icon}</>
                    {panelStatus && <>{item.title}</>}
                    {item.unReadCount && item.unReadCount > 0 ? (
                      <span className={styles[`unread-count-wrapper`]}>
                        {panelStatus && item.unReadCount}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                );
              })} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubSidebar;
